import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const Indoor = ({ data, active = true, ...props }) => {
  return (
    <div>
        <div className={"test-photos-wrapper"}>

          <table className={"table"}>
            <tr>
                  <th>ROXTEC INSIDE VIEW </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 0
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Installation and Labels of feeders, optical fiber and dc cables in the INDOOR cable ladder.</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 1
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Ac cable view (from TGBT to ac box).</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 2
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Ac cable view (from ac box to rbs) with lables.</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 3
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>dc cable view (from rbs to bbs) with lables. </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 4
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th> RBS VIEW.  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 5
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>bBS VIEW. </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 6
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Rbs & bbs fixation and double nut for outdoor rbs </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 7
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Du’s & ru’s in right positions & fixed.  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 8
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Main unit / Base band fixed and grounded.  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 9
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Batteries installation.   </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 10
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Fuse bbs view. </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 11
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>temperature sensor installation.</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 12
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>installation of Evacuation liquid tube in rbs outdoor</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 13
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Tgbt view (circuit breakers and surge arrester). </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 14
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th> Air conditioner view</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 15
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Ddf view (fixation and grounding).</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 16
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Ddf view (sdp, ovp alarm & trans). </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 17
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Ac box view (label and fixation).  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 18
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>dc box view (label and fixation) if exist.  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 19
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>Optical fiber boxes (fixation and labelding).  </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 20
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>AMM trans installation </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 21
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th>AMM trans (fixation and label). </th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 22
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>

              <tr>
                  <th> Upgrade power Material Installed.</th>
                  {data.photos && (
                  <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                      key === 23
                      &&
                      <PhotoItem photo={photo} active={active} />
                    ))}
                  </div>
                  )}
              </tr>


          </table>
      </div>

      <h6>AUpgrade power informations</h6>

      <div className={"row col-md-6"}>
        <label>PCU AC : </label>
        <div className="col">
          {data.site.PCU_AC_type? <div className="row"> <b>Type </b>--> {data.site.PCU_AC_type} </div>: ''} 
          {data.site.PCU_AC_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.PCU_AC_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>PCU DC : </label>
        <div className="col">
          {data.site.PCU_DC_type? <div className="row"> <b>Type </b>--> {data.site.PCU_DC_type} </div>: ''} 
          {data.site.PCU_DC_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.PCU_DC_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>PCF : </label>
        <div className="col">
          {data.site.PCF_type? <div className="row"> <b>Type </b>--> {data.site.PCF_type} </div>: ''} 
          {data.site.PCF_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.PCF_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>BFU : </label>
        <div className="col">
          {data.site.BFU_type? <div className="row"> <b>Type</b>--> {data.site.BFU_type} </div>: ''} 
          {data.site.BFU_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.BFU_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>DC power cable Busbar : </label>
        <div className="col">
          {data.site.DC_power_cable_Busbar_type? <div className="row"> <b>Type </b>--> {data.site.DC_power_cable_Busbar_type} </div>: ''} 
          {data.site.DC_power_cable_Busbar_Quantity? <div className="row"> <b>Quantity </b>--> {data.site.DC_power_cable_Busbar_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Busbar : </label>
        <div className="col">
          {data.site.Busbar_type? <div className="row"> <b>Type </b>--> {data.site.Busbar_type} </div>: ''} 
          {data.site.Busbar_Quantity? <div className="row"> <b>Quantity </b>--> {data.site.Busbar_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>PSU : </label>
        <div className="col">
          {data.site.PSU_type? <div className="row"> <b>Type</b>--> {data.site.PSU_type} </div>: ''} 
          {data.site.PSU_Quantity? <div className="row"> <b>Quantity </b>--> {data.site.PSU_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>DC cable section between RBS & BBS : </label>
        <div className="col">
          {data.site.DC_cable_section_between_RBS_BBS_type? <div className="row"> <b>Type</b>--> {data.site.DC_cable_section_between_RBS_BBS_type} </div>: ''} 
          {data.site.DC_cable_section_between_RBS_BBS_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.DC_cable_section_between_RBS_BBS_Quantity} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Fuse : </label>
        <div className="col">
          {data.site.Fuse_type? <div className="row"> <b>Type </b>--> {data.site.Fuse_type} </div>: ''} 
          {data.site.Fuse_Quantity? <div className="row"> <b>Quantity</b>--> {data.site.Fuse_Quantity} </div>: ''} 
        </div>
      </div>


      <div className={"test-photos-wrapper"}>

        <table className={"table"}>
          <tr>
              <th> General earthing (Rbs, bbs & ddf…). </th>
              {data.photos && (
              <div className={"test-photos-wrapper"}>
                {data.photos.map((photo, key) => (
                  key === 24
                  &&
                  <PhotoItem photo={photo} active={active} />
                ))}
              </div>
              )}
          </tr>

          <tr>
              <th> view of others installed equipments </th>
              {data.photos && (
              <div className={"test-photos-wrapper"}>
                {data.photos.map((photo, key) => (
                  key === 25
                  &&
                  <PhotoItem photo={photo} active={active} />
                ))}
              </div>
              )}
          </tr>

          <tr>
              <th> Unused Material or voucher of return</th>
              {data.photos && (
              <div className={"test-photos-wrapper"}>
                {data.photos.map((photo, key) => (
                  key === 26
                  &&
                  <PhotoItem photo={photo} active={active} />
                ))}
              </div>
              )}
          </tr>

          <tr>
              <th>Indoor general clean up</th>
              {data.photos && (
              <div className={"test-photos-wrapper"}>
                {data.photos.map((photo, key) => (
                  key === 27
                  &&
                  <PhotoItem photo={photo} active={active} />
                ))}
              </div>
              )}
          </tr>

        </table>

      </div>


              
      


    </div>
    
  );
};

export default Indoor;
