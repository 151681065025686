import React from "react";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
} from "../../Redux/actions/index";
import { Link } from "react-router-dom";

import api from "../../Utils/Api";

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: (e) => dispatch(doneLoading(e)),
  };
};

class ManagerDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
      fullname: null,
      tasks: null,
      lastonline: null,
      stores: null,
      visits: null,
      account_username: "",
      account_firstname: "",
      account_lastname: "",
      account_email: "",
      account_phone: "",
      last_task: {},
      total_tasks_month: 0,
      logs: [],
      data: {},
    };

    props.isLoading();
  }

  componentDidMount() {
    const that = this;

    api
      .get("/api/manager/detail/" + this.props.match.params.userid)
      .then((response) => {
        if (response.data.success) this.setState({ ...response.data });
      })
      .then((r) => this.props.doneLoading());
  }

  render() {
    return (
      <>

<div className={"container-header"}>Managers</div>
        <div className={"row mb-5"}>
          <div className={"col-md-6"}>
            {this.props.user.userid === this.state.account_id && (
              <a href={"/account"}>Modifier mes informations</a>
            )}

            <h4 className={"section-title"}>Information générale</h4>
            {/* <h4>{this.state.account_username} </h4> */}

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Nom d'utilisateur</span>
              <span className={"detail-value"}>
                {this.state.account_username}
              </span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Nom</span>
              <span className={"detail-value"}>
                {this.state.account_firstname +
                  " " +
                  this.state.account_lastname.toUpperCase()}
              </span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Adresse mail</span>
              <span className={"detail-value"}>{this.state.account_email}</span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Numéro de téléphone</span>
              <span className={"detail-value"}>{this.state.account_phone}</span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Type</span>
              <span className={"detail-value"}>{this.state.account_type}</span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Cluster</span>
              <span className={"detail-value"}>{this.state.cluster}</span>
            </div>
            <div className={"detail-pair"}>
              <span className={"detail-key"}>Cluster Manager</span>
              <span className={"detail-value"}>
                {this.state.cluster_manager_username}
              </span>
            </div>

          </div>

          <div className={"col-md-6"}>
                <h4 className={"section-title"}>Activités</h4>


                <div className={"detail-pair"}>
                    <span className={"detail-key"}>Derniere connexion</span>
                    <span className={"detail-value"}>
                        {this.state.last_online}
                    </span>
                </div>

                <table className={"table"}>

				{this.state.logs.map(item => (
					<tr>
						<td>{item.created_at}</td>
						<td style={{textAlign: "right"}}>{item.action}</td>
					</tr>	
				))}
				</table>


          </div>
        </div>

        {/* <div className={"row mt-6"}>
          <div className={"col-md-8 offset-md-2"}>
            <h4 className={"section-title"}>Tâches PM</h4>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Total PM effectuées</span>
              <span className={"detail-value"}>{this.state.total_tasks} </span>
              <Link
                className={"ml-5 task-action-btn"}
                to={"/task/agent/" + this.props.match.params.userid}
              >
                Voir tout
              </Link>{" "}
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>PM effectuées ce mois</span>
              <span className={"detail-value"}>
                {this.state.total_tasks_month}{" "}
              </span>
            </div>

            <div className={"detail-pair"}>
              <span className={"detail-key"}>Dernière PM effectuées</span>
              <span className={"detail-value"}>
                <Link to={"/task/" + this.state.last_task.id}>
                  {this.state.last_task.created_at} <br />
                  {this.state.last_task.site_name} <br />
                  {this.state.last_task.site_code}
                </Link>
              </span>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerDetail);
