import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";


export default class Brands extends React.Component {


    constructor(props)  {
        super(props);

        this.state = {
            power_supply: [],
            batteries: [],
            ac_brands: [],
            ac_capacity: [],
            ge_brands: [],
            loading: true,
            bss_brands: [],
        }
    }


    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        api.get('/api/settings/brands')
        .then(res => {
            this.setState({loading: false});

            if(res.data.success)
                this.setState({ ...res.data.data })
        })
    }

    addPowerSupply = () => {
        const {power_supply} = this.state;

        power_supply.push({item_label: "", item_id: "", editable: true, item_type: "ps"})

        this.setState({
            power_supply: power_supply
        })

    }

    addItem = (type) => {
        const selectedType = this.state[type];

        selectedType.push({item_label: "", item_id: "", editable: true})


        this.setState({
             [type]: selectedType
        })

    }

    deleteItem = (item_type, item_id, item_idx, type) => {

        let selectedType = this.state[type];

        selectedType.splice(item_idx, 1);

        this.setState({[type]: selectedType})

        if(item_id)
            this.loadData()

    }

    render() {

        return (
            <>
            <h3>
                Marques & modèles
                {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin className={"ml-3"} />}
            </h3>
            <div className={"row mt-5"}>

                <ItemType
                    label={"AC"}
                    type={"ac_brands"}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                    data={this.state.ac_brands}
                />

                <ItemType
                    label={"Power Supply"}
                    type={"power_supply"}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                    data={this.state.power_supply}
                />

                <ItemType
                    label={"Batteries"}
                    type={"batteries"}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                    data={this.state.batteries}
                />


            </div>

            <div className={"row mt-3"}>

                <ItemType label={"AC Capacity"} type={"ac_capacity"} data={this.state.ac_capacity}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                />

                <ItemType
                    label={"GE"}
                    type={"ge_brands"}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                    data={this.state.ge_brands}
                />

                <ItemType
                    label={"BSS"}
                    type={"bss_brands"}
                    deleteItem={this.deleteItem}
                    addItem={this.addItem}
                    data={this.state.bss_brands}
                />

            </div>
            </>

        )
    }
}


const ItemType = ({type, label, deleteItem, addItem, data, ...props}) => {


    return (
        <div className={"col-md-4"}>
            <div className={"single-item-type"}>
                <h4>
                    {label}
                    <button className={"float-right btn btn-primary btn-sm"} onClick={() => addItem(type)}><b>Ajouter</b></button>
                    <div className={"clearfix mb-3"}></div>
                </h4>

                <ul className={"custom-list"}>
                    {data.map((item, idx) => (
                        <SingleRow
                            key={item.item_id}
                            deleteItem={() => deleteItem(item.item_type, item.item_id, idx, type)}
                            item={item}
                            item_type={type}
                        />

                    ))}
                </ul>
            </div>
        </div>
    )
}

const SingleRow = ({item, deleteItem, item_type, ...props}) => {


    const [isEditable, setEditable] = useState(item.editable);

    const [text, setText] = useState(item.item_label);

    const [loading, setLoading] = useState(false);

    const textRef = useRef(null);


    const deleteReq = (e) => {


        if(item.item_id) {

            setLoading(true);

            const data = {
                item_id: item.item_id
            }

            api.post('/api/settings/brands/delete', data)
            .then(res => {
                setLoading(false);
                console.log(res.data);
                deleteItem();
            });
        } else {
            deleteItem();
        }



    }

    const submitForm = (e) => {

        e.preventDefault();
        if(!isEditable) {
            setEditable(true);
            return;
        }

        setText(textRef.current.value.length ? textRef.current.value : item.item_label);
        setEditable(false);

        setLoading(true);

        const data = {
            item_id: item.item_id,
            item_label: textRef.current.value.length ? textRef.current.value : item.item_label,
            item_type: item_type
        }

        api.post('/api/settings/brands/update', data)
        .then(res => { setLoading(false); console.log(res.data) })

    }



    return (
        <li>
            <form onSubmit={submitForm}>
            <span className={"item-label"}>
                {!isEditable ? text : (

                        <input ref={textRef} className={"form-control"} defaultValue={text} type="text" />

                )}
            </span>

            <span className={"buttons-col"+ ( loading ? " show" : "")}>
                {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                ) : (
                    <>
                    <button type={"submit"}  className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={!isEditable ? faEdit : faSave} /></button>
                    <button type="button" onClick={deleteReq} className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={faTrash} /></button>
                    </>
                )}
            </span>
            </form>
        </li>
    )
}
