import React , { useState, useRef, useEffect, useCallback}from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import Restricted from "../../../Utils/Restricted";


import { MapContainer, TileLayer, Marker, Popup , GeoJSON , FeatureGroup} from 'react-leaflet';
import L from 'leaflet'
import ReactLeafletKml from 'react-leaflet-kml';
// import { EditControl } from "react-leaflet-draw"
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/images/marker-shadow.png";
import { circle, Icon } from "leaflet";
import 'leaflet-draw'
import rewind from "@mapbox/geojson-rewind";
import * as tj from "@mapbox/togeojson";
import Constants from "../../../Utils/Constants";
import { CirclePicker, SketchPicker , TwitterPicker} from 'react-color';
import XMLParser from 'react-xml-parser';
import convert from 'xml-js';
// import "leaflet-draw/dist/leaflet.draw.css"
// import "leaflet-draw/dist/leaflet.draw"

import 'leaflet/dist/leaflet.css' 
//import 'leaflet/dist/leaflet' 
// import 'leaflet-draw' 
import 'leaflet-draw/dist/leaflet.draw.css'
import Draw from 'leaflet-draw';
import EDIT, { EditOnlyControl } from "./d";
const CoSiteMapBalise = ({id,file,color,setData,  ...props}) => {
   
    const parser = new DOMParser();
    const [fileKml , setFileKml ] = useState(null)
    const [kml, setKml] = useState(null)
    const [polColor , setPolColor] = useState( '#0fcc04')
    const [mapContainer, setMapContainer] = useState(null)
    const [featuregrpupe, setFeatureGroup] = useState(null)
    const [editcontrol, setEditControl ] = useState(null)
    const [loading, setLoading] = useState(false)
    const [features, setFeatures] = useState([])
   

    useEffect(() => {
        if(!file){
            loadKML()
           // setKml(parser.parseFromString(file, "text/xml"))
        }
    }, [file]);  

    useEffect(() => {
        if(color  ){
            setPolColor(color)
           // DrawingContainer(mapContainer)
           // setKml(parser.parseFromString(file, "text/xml"))
        }
    }, [color ]);  
    useEffect(() => {
        if(polColor &&  mapContainer && featuregrpupe){
            DrawingContainer(mapContainer , featuregrpupe)
          
        }
    }, [polColor]);  

    // console.log(fileKml);
   // const [color , setColor] = useState(color)
    
    // useEffect(() => {
    //         //selectAsset();
    // }, []); 
 
    // useEffect(() => {
    //     const results = data.filter((d) =>
    //       d[2].toLowerCase().includes(searchTerm)
    //     );
    //     setSearchResults(results);
    //     setJsonData(results)
    // }, [searchTerm]);
    
//   const submitUploadMap = (e) =>{
    
//     e.preventDefault();
//     const data = new FormData(e.target);
//         api.post(e.target.action+'/'+ this.props.item.id, data, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         })
//         .then(res => {
//         })
       
//   }


    const _onEdited = e  => { 
        console.log('edit')
    } 
    const _onDeleted = e  => { 
        console.log('delete')
    } 

    //if data
    const  loadKML = () => {
        setLoading(true)
        api.get(`/api/cosite/getData/${id}`).then(res => {
          if(res.data){
                // let xml = parser.parseFromString(res.data.file, "text/xml");
                // const converted = tj.kml(xml);
                // console.log( JSON.stringify(converted))
                console.log('version '+  L.drawVersion)
                let converted = res.data.data
                setKml(converted)
                // const container = document.getElementById('mapId'+id)
                // if( container ){
                //     let  map = L.map('mapId'+id ,  { drawControl: true } , { style: {height: '220px'}}).setView([36.000, 2.09], 5)
                //     console.log('map => '+ map)
                //     let buildLayer = L.geoJSON(converted , 
                //          {
                //         style: function(feature) {
                //             console.log ('ffff' + feature.properties['stroke-width'])
                //             return {
                //                 color : feature.properties.fill ? feature.properties.fill : polColor, 
                //                 weight: feature.properties['stroke-width'] ?  feature.properties['stroke-width'] : 1
                //             };
                //             // switch (feature.properties.name) {
                //             //     case 'Marker 2': return {color: "#F44336"};
                //             //     case 'Democrat':   return {color: "#0000ff"};
                //             // }
                //         }}
                //         )
                //         //.addTo(map);
                //         setFeatures([converted])
                        
                //         L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                //             attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //         }).addTo(map);
                //         var drawnItems = new L.FeatureGroup();  
                //         map.addLayer(drawnItems);
                //         buildLayer.eachLayer(function (layer) {
                //             drawnItems.addLayer(layer);
                //         });
                //         setFeatureGroup(drawnItems)
                //         setMapContainer(map)
                //         DrawingContainer(map, drawnItems)
                // }
                setLoading(false)
            }
        });
    }

    const DrawingContainer = (map , featureG) =>{ 
        console.log('jejng'+editcontrol)
       
        
        if(editcontrol){
            map.removeControl(editcontrol)
        }
        var drawControl = new L.Control.Draw( {
            edit: {
                featureGroup: featureG,
            },
            draw: {
                rectangle: false,
                polyline:false,
                circle: false,
                circlemarker:false,
                polygon: {
                    shapeOptions: {
                        color : polColor,
                        weight: 2
                    }
                },
            },
            // onEdited:_onEdited,
            // onDeleted:_onDeleted,
            position:"topright" ,
        });


        setEditControl(drawControl)
        map.addControl(drawControl);
       
    }
    mapContainer  && mapContainer.on('draw:created', function (e) {
        
        var type = e.layerType,
            layer = e.layer;
          let  feature = layer.feature = layer.feature || {};

        feature.type = feature.type || "Feature"; // Initialize feature.type
        var props = feature.properties = feature.properties || {}; // Initialize feature.properties
        props.fill = polColor;
        props['stroke-width'] = 1
        if (type === 'marker') {
            // Do marker specific actions
        }


        // let l = layer.toGeoJSON()
        // l.properties.fill = polColor
        // l.properties['stroke-width'] = 1
        // console.log('l' +  JSON.stringify(l) )
        // setFeatures([...features, l])
        // setData([...features, l])

        setFeatures(featuregrpupe.toGeoJSON())
        setData(featuregrpupe.toGeoJSON())

       
        mapContainer.addLayer(layer);
        featuregrpupe.addLayer(layer) // add this line to allow the editing and delelting 
        console.log('see features '+ JSON.stringify(featuregrpupe.toGeoJSON()))
    });
    mapContainer && mapContainer.on('draw:edited',  function (e) {
        //console.log('see what '+ JSON.stringify(featuregrpupe.toGeoJSON()))
        var layers = e.layers;

        //setFeatures([])
       // let array = []
        layers.eachLayer(function (layer) {
            
        //     let l = layer.toGeoJSON()
        //     l.properties.fill = polColor
        //     l.properties['stroke-width'] = 1
        //    // array.push(l)
        //     console.log('l' +  JSON.stringify(l) )
        //     setFeatures([...features, l])
        //     setData([...features, l])
            setFeatures(featuregrpupe.toGeoJSON())
            setData(featuregrpupe.toGeoJSON())
        });
    });
    mapContainer && mapContainer.on('draw:deleted', function (e) {
        e.layers.eachLayer(layer => {
            featuregrpupe.removeLayer(layer);
        });
        setFeatures(featuregrpupe.toGeoJSON())
        setData(featuregrpupe.toGeoJSON())
    });

    const _onCreated = e  => { 
        console.log('create')
    } 
    // const setData = () => {
    //     let data = {
    //         "type":"FeatureCollection",
    //         "features":features
    //     }
    //     api.post('/api/cosite/setData/'+id,data).then(res =>  {
    //         if(res.data){
    //             console.log('res'+res.data.req)
    //         }
    //     })
    // }

    // const  loadKML = () => {
    //     //let that = this
    //     api.get(`/api/cosite/getfile/${id}`).then(res => {
    //       if(res.data){
    //             setFileKml(res.data.file)
    //             let kml = parser.parseFromString(res.data.file, "text/xml");
    //             setKml(kml)
    //             //setFileKml(kml)
    //       }
    //     });
    // }

    const onSelect = (key) =>  {
        this.setState({key});
        setTimeout(() => {
          this.refs['map' + key].getLeafletElement().invalidateSize(false);
        }, 300); // Adjust timeout to tab transition
    }


    return (
     
          <div style={{height: '550px'}}>


            {loading && <FontAwesomeIcon icon={faSpinner} className={"ml-2"} style={{ fontSize: 16 }} spin />}
            {/* <div  style={{height: '450px', width: '100%' , margin: 'auto'}} className={"col-md-12 map"} id={'mapId'+id}>
            
            </div> */}
 
              
              <MapContainer center={[36.000, 2.09]} zoom={5} scrollWheelZoom={true} className="map"  style={{height: '500px'}}>
                    <FeatureGroup>
                        {/* <EditControl 
                            position="topright" 
                            onCreated={_onCreated}
                            onEdited={_onEdited}
                            onDeleted={_onDeleted}
                            draw={{
                              rectangle: false,
                              polyline:false,
                              polygon: {
                                shapeOptions: {
                                    color:['#7bfc03'],
                                    weight: 2
                                }
                              },
                              circle:false,
                              // circlemarker:false,
                              marker:false,
                            }}
                            
                            // edit={{
                            //   selectedPathOptions: {
                            //       maintainColor: false,
                            //       color: this.state.color,
                            //       fillColor: this.state.color,
                            //       //opacity: 0.3
                            //   },
                            //   shapeOptions: { color: '#7bfc03' },

                            // }}
                        /> */}
                    </FeatureGroup>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    
                    
              </MapContainer>
          </div>
           
            
         
          
    )
}
export default CoSiteMapBalise;
