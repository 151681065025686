import React from "react";
import { Chart } from "chart.js";
import * as Gauge from "chartjs-gauge";
import { chartColors } from "../../../../Utils/Helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faFilter, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../../Utils/Api";


const moment = require("moment");

class TasksPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedCluster: props.session.user.cluster,
      selectedOwner: "",
      monthly: [],
      perOwner: [],
      perCluster: [],
      perOwnerDetail: [],
      perWilaya: [],
      rowActive: "",
      startDate: moment().format("YYYY-01-01 00:00:00"),
      endDate: moment().format("YYYY-12-31 23:59:00")
    };
  }

  loadData = async () => {
    this.setState({ loading: true });
    const getData = await api.get(
      `/api/dashboard/tasks?start_date=${moment(this.state.startDate).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}&cluster=${
        this.state.selectedCluster
      }&owner=${this.state.selectedOwner}`
    );

    this.setState({ ...getData.data.data, loading: false });

    return getData;
  };



  drawGauges = () =>  {

    return;
    const totalDone = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done),0)
    const totalPlanned = Object.values(this.state.monthly).reduce((acc, cur) => acc + parseInt(cur.planned), 0);

    if(this.doneChart)
      this.doneChart.destroy();


    const donePerc = ( totalDone / totalPlanned*100).toFixed(2);

    this.doneChart = new Chart(this.doneCanvas.getContext("2d"), {
      type: "gauge",
      data: {
        datasets: [
          {
            label: "Planifié",
            data: [10, 70, 100],
            value: donePerc,
            backgroundColor: [chartColors[2], chartColors[1], chartColors[4]],
            barThickness: 2,
            borderWidth: 1
          }
        ]
      },
      options:{
        cutoutPercentage: 90,
        responsive: true,
        title: {
          display: false
        },
        layout: {
          padding: {
            bottom: 30
          }
        },
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: -95,
          color: chartColors[7],

        },
        valueLabel: {
          formatter: (v) => v+"%"
        }
      }

    });




    if(this.ontimeChart)
      this.ontimeChart.destroy();


    const totalOntime = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_ontime),0)
    const ontimePerc = (totalOntime/totalDone*100).toFixed(2);

    this.ontimeChart = new Chart(this.ontimeCanvas.getContext("2d"), {
      type: "gauge",
      data: {
        datasets: [
          {
            label: "Respect Delivery Plan",
            data: [10, 70, 100],
            value: ontimePerc,
            backgroundColor: [chartColors[2], chartColors[1], chartColors[4]],
            barThickness: 2,
            borderWidth: 1
          }
        ]
      },
      options:{
        cutoutPercentage: 90,
        responsive: true,
        title: {
          display: false
        },
        layout: {
          padding: {
            bottom: 30
          }
        },
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: -95,
          color: chartColors[7],

        },
        valueLabel: {
          formatter: (v) => v+"%"
        }
      }

    });






    const totalLate = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_late),0)
    const latePerc = (totalLate/totalDone*100).toFixed(2);


    if(this.lateChart)
      this.lateChart.destroy();

    this.lateChart = new Chart(this.lateCanvas.getContext("2d"), {
      type: "gauge",
      data: {
        datasets: [
          {
            label: "Hors délais",
            data: [10, 70, 100],
            value: (latePerc-100)*-1,
            backgroundColor: [chartColors[2], chartColors[1], chartColors[4]],
            barThickness: 2,
            borderWidth: 1
          }
        ]
      },
      options:{
        cutoutPercentage: 90,
        responsive: true,
        tooltips: {
          titleFontSize: 19,
        },
        title: {
          display: false
        },
        layout: {
          padding: {
            bottom: 30
          }
        },
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: -95,
          color: chartColors[7],

        },
        valueLabel: {
          formatter: (v) => (v-100)*-1+"%"
        }
      }

    });




  }

  drawGraphs = async () => {
    const res = await this.loadData();

    if (this.lineChart) this.lineChart.destroy();


    this.drawGauges();

    const data = res.data.data.monthly;
    const totalPlanned = data
      ? Object.values(data).reduce((acc, cur) => acc + parseInt(cur.planned), 0)
      : 0;

    const totalDone = data
      ? Object.values(data).reduce((acc, cur) => acc + parseInt(cur.done), 0)
      : 0;

    var ctx = this.tasksCanvas.getContext("2d");
    ctx.canvas.width = 1000;
    ctx.canvas.height = 300;

    var color = Chart.helpers.color;
    var cfg = {
      plugins: [ChartDataLabels],
      data: {
        datasets: [
          {
            label: "Planifié",
            backgroundColor: chartColors[0],
            borderColor: chartColors[0],
            data: data.map((item, idx) => {
              const totalUntilNow = Object.values(data)
                .slice(0, idx + 1)
                .reduce((acc, cur) => acc + parseInt(cur.planned), 0);

              return {
                x: item.day,
                val: item.planned,
                y: Math.ceil((totalUntilNow / totalPlanned) * 10000) / 100
              };
            }),
            type: "line",
            pointRadius: 0,
            fill: false,
            lineTension: 0.2,
            borderWidth: 2,
            datalabels: {
              align: -75,
              color: chartColors[0]
            }
          },
          {
            label: "Done",
            backgroundColor: chartColors[4],
            //   .alpha(0.5)
            //   .rgbString(),
            borderColor: chartColors[4],
            data: data.map((item, idx) => {
              const totalDoneUntilNow = Object.values(data)
                .slice(0, idx + 1)
                .reduce((acc, cur) => acc + parseInt(cur.done), 0);

              return {
                x: item.day,
                val: item.done,
                y: Math.ceil((totalDoneUntilNow / totalPlanned) * 10000) / 100
              };
            }),
            type: "line",
            pointRadius: 0,
            fill: false,
            lineTension: 0.2,
            borderWidth: 2,
            datalabels: {
              align: 45,
              color: chartColors[4]
            }
          }
        ]
      },
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            enable: true,
            formatter: f => {
              return f.y + "%";
            },
            color: "#FFF",
            font: {
              weight: "bold"
            }
          }
        },
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "week",
                unitStepSize: 1,
                displayFormats: {
                  week: "DD MMMM"
                }
              },
              distribution: "series",
              offset: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false
              },
              scaleLabel: {
                display: true,
                label: "aze"
              }
            }
          ]
        },
        tooltips: {
          intersect: false,
          mode: "index",
          callbacks: {
            title: (tooltip, data) => {
              return "Week #"+moment(tooltip[0].label).format("W");
            },
            label: function(tooltipItem, myData) {
              var label = myData.datasets[tooltipItem.datasetIndex].label || "";
              const val =
                myData.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ].val;

              if (label) {
                label += ": ";
              }
              label +=
                val +
                `
              (cumul: ${parseFloat(tooltipItem.value).toFixed(2)}%)`; //;
              return label;
            }
          }
        }
      }
    };

    this.lineChart = new Chart(ctx, cfg);
  };

  componentDidMount() {
    this.drawGraphs();
  }

  hoverRow = idx => {
    this.setState({ rowActive: "row-" + idx });
  };
  unhoverRow = idx => {
    this.setState({ rowActive: "" });
  };

  setStartDate = e => this.setState({ startDate: e });
  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {
    this.setState(filters);
  };

  render() {
    const totalPlanned = this.state.perOwnerDetail.reduce(
      (acc, curr) => acc + parseInt(curr.planned),
      0
    );

    const totalDone = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done), 0);
    const totalstarted = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.ongoing), 0);
    const totalaccepted = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.accept), 0);
    const totalrejected = this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.reject), 0);

    const totalPlannedPerOwner = owner => {
      return this.state.perOwnerDetail
        .filter(d => d.owner === owner)
        .reduce((acc, curr) => acc + parseInt(curr.planned), 0);
    };

    const totalDonePerOwner = owner =>
      this.state.perOwnerDetail
        .filter(d => d.owner === owner)
        .reduce((acc, curr) => acc + parseInt(curr.done), 0);

    return (
      <>

      <div className={"dashboard-page"}>
        <div className={"page-loading "+(this.state.loading ? "active": "")}></div>
        <div className={"container-header header-spacing nmb"}>
          <div></div>
          <div className={"date-filters"}>
            <>
              <b>Owner</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={this.props.filters.site_owners}
                placeholder={"Owner"}
                value={this.props.session.user.owner ? {label: this.props.session.user.owner, value: this.props.session.user.owner === "O&M" ? "inhouse" : this.props.session.user.owner} : undefined}
                isDisabled={!!this.props.session.user.owner}
                onChange={e => this.updateFilters({ selectedOwner: e.value })}
              />

              <b>Cluster</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "Tous", value: "" },
                  ...this.props.filters.clusters
                ]}
                value={this.props.session.user.cluster ? {label: this.props.session.user.cluster, value: this.props.session.user.cluster} : undefined}
                isDisabled={!!this.props.session.user.cluster}
                placeholder={"Cluster"}
                onChange={e => this.updateFilters({ selectedCluster: e.value })}
              />

              <b>Periode </b>
              <ReactDatePicker
              dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                onChange={date => this.setStartDate(date)}
                selectsStart
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                className={"dashboard-filter-input"}
              />
              <ReactDatePicker
              dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                onChange={date => this.setEndDate(date)}
                selectsEnd
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                minDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                className={"dashboard-filter-input ml-3"}
              />

              <button
                onClick={this.drawGraphs}
                type={"button"}
                className={"filter-apply"}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>




        <div className={"row"} />
        <div className={"row dashboard-row"}>

          <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Planifiés</span>
                <span className={"dashboard-box-value"}>{totalPlanned}</span>
              </div>

              <div className={"single-chart"}>

                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={
                          this.state.rowActive === "row-" + idx
                            ? "active-row"
                            : ""
                        }
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.planned +
                            ` (${parseFloat(
                              //(item.planned / totalPlanned) * 100
                              (item.planned / item.siteNbr) * 100
                            ).toFixed(2)}%)`}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Réalisés</span>
                <span className={"dashboard-box-value"}>{totalDone} <span className={"dashboard-percentage"}>({(totalDone/totalPlanned*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                {/* <h3>
                  <canvas ref={ref => this.doneCanvas = ref}></canvas>
                </h3> */}


                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={this.state.rowActive === "row-" + idx ? "active-row" : ""}
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.done +
                            ` (${parseFloat(
                              totalPlannedPerOwner(item.owner) > 0
                                //? parseFloat((item.done / totalPlannedPerOwner(item.owner)) * 100 )
                                ? parseFloat((item.done / item.siteNbr) * 100 )
                                : 0
                            ).toFixed(2)}%)`}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className={"col-md-2 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Commencés</span>
                <span className={"dashboard-box-value"}>{totalstarted} <span className={"dashboard-percentage"}>({(totalstarted/totalPlanned*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={this.state.rowActive === "row-" + idx ? "active-row" : ""}
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.ongoing +
                            ` (${parseFloat(
                              totalPlannedPerOwner(item.owner) > 0
                               
                                ? parseFloat((item.ongoing / item.siteNbr) * 100 )
                                : 0
                            ).toFixed(2)}%)`}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>acceptés</span>
                <span className={"dashboard-box-value"}>{totalaccepted} <span className={"dashboard-percentage"}>({(totalaccepted/totalPlanned*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={this.state.rowActive === "row-" + idx ? "active-row" : ""}
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.accept +
                            ` (${parseFloat(
                              totalPlannedPerOwner(item.owner) > 0
                               
                                ? parseFloat((item.accept / item.siteNbr) * 100 )
                                : 0
                            ).toFixed(2)}%)`}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Rejetés</span>
                <span className={"dashboard-box-value"}>{totalrejected} <span className={"dashboard-percentage"}>({(totalrejected/totalPlanned*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={this.state.rowActive === "row-" + idx ? "active-row" : ""}
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.reject +
                            ` (${parseFloat(
                              totalPlannedPerOwner(item.owner) > 0
                               
                                ? parseFloat((item.reject / item.siteNbr) * 100 )
                                : 0
                            ).toFixed(2)}%)`}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Dans les délais</span>
                <span className={"dashboard-box-value"}>{this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_ontime),0)} <span className={"dashboard-percentage"}>({(this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_ontime),0) / totalDone*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                
                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={
                          this.state.rowActive === "row-" + idx
                            ? "active-row"
                            : ""
                        }
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.done_ontime +
                            ` (${
                              totalDonePerOwner(item.owner) > 0
                                ? parseFloat(
                                    (item.done_ontime /
                                      totalDonePerOwner(item.owner)) *
                                      100
                                  ).toFixed(2)
                                : 0
                            }%)`}{" "}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          {/* <div className={"col-md-3 "}>
            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Hors délais</span>
                <span className={"dashboard-box-value"}>{this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_late),0)} <span className={"dashboard-percentage"}>({(this.state.perOwnerDetail.reduce((acc, curr) => acc + parseInt(curr.done_late),0) / totalDone*100).toFixed(2)+"%"})</span></span>
              </div>

              <div className={"single-chart"}>
                
                <table className={"table"}>
                  <tbody>
                    {this.state.perOwnerDetail.map((item, idx) => (
                      <tr
                        className={
                          this.state.rowActive === "row-" + idx
                            ? "active-row"
                            : ""
                        }
                        onMouseEnter={() => this.hoverRow(idx)}
                        onMouseLeave={() => this.unhoverRow(idx)}
                      >
                        <th>{item.owner}</th>
                        <th>
                          {item.done_late +
                            ` (${
                              totalDonePerOwner(item.owner) > 0
                                ? parseFloat(
                                    (item.done_late /
                                      totalDonePerOwner(item.owner)) *
                                      100
                                  ).toFixed(2)
                                : 0
                            }%)`}{" "}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>

        <div className={"row dashboard-row "}>
          <div className={"col-md-6"}>
            {/* <div className={"single-chart p-3"}>
              <h3 className={"mb-3"}>Tâches plan per owner</h3>
              <Pie
                options={{
                  legend:{
                    position:"right",
                    labels:{
                      fontColor: "#333",
                      fontFamily: "Open sans"
                    }
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      formatter: (v, c) => {
                       return (v/totalPlanned*100).toFixed(2)+"%"
                      },
                      anchor: "end",
                      color: "#333",
                      align: "end",
                      backgroundColor: "rgba(0,0,0,.1)",
                      font: {
                        size: 15,
                        family: "Open sans",
                        weight: "bold"
                      }
                    }
                  }
                }}
                plugins={[ChartDataLabels]}
                data={{
                  labels: this.state.perOwner.map(item => item.owner),
                  datasets: [
                    {
                      data: this.state.perOwner.map(item => item.count),
                      backgroundColor: chartColors,
                      hoverBackgroundColor: chartColors
                    }
                  ]
                }}
              />
            </div> */}
            <div className={"single-chart"}>
              <canvas ref={ref => (this.tasksCanvas = ref)} />
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={"single-chart"}>
              {this.state.selectedCluster ? (
                <h3>Delivery plan per wilaya</h3>
              ) : (
                <h3>Delivery plan per cluster</h3>
              )}

              <table className={"table"}>
                <thead>
                  <tr>
                    <th>{this.state.selectedCluster ? "Wilaya" : "Cluster"}</th>
                    <th>Plan</th>
                    <th>Done</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedCluster
                    ? this.state.perWilaya.map(item => (
                        <tr>
                          <th>{item.address_wilaya}</th>
                          <th>{item.count}</th>
                          <th>{item.done+" ("+ (item.done/item.count*100).toFixed(2)+"%)"}</th>
                        </tr>
                      ))
                    : this.state.perCluster.map(item => (
                        <tr>
                          <th>{item.cluster}</th>
                          <th>{item.count}</th>
                          <th>{item.done+" ("+ (item.done/item.count*100).toFixed(2)+"%)"}</th>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <div className={"row dashboard-row "}>
          <div className={"col-md-12"}>
            <div className={"single-chart"}>
              <canvas ref={ref => (this.tasksCanvas = ref)} />
            </div>
          </div>
        </div> */}

        <h2>Détail</h2>
        <div className={"row dashboard-row "} />
      </div>

      </>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  };
};
export default connect(mapState)(TasksPage);
