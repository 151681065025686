import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import { useDispatch } from "react-redux";
import { startLoading, setFlash, doneLoading } from "../../../Redux/actions";

const PlanPmOverlay = ({
  open,
  siteid,
  site,
  defaultAgent,
  loadSites,
  close,
  defaultDate,
  taskType,
  subsite,
  ...props
}) => {

  const dispatch = useDispatch();

  const isLoading = () => dispatch(startLoading());
  const flash = (d) => dispatch(setFlash(d));
  const notLoading = () => dispatch(doneLoading())

  const submitAssignForm = (e) => {
    e.preventDefault();

    // if (!this.state.date || !this.state.agentid) {
    //   return false;
    // }


    isLoading();

    let data = formToObject(e.target);

    data['selected_site'] = subsite ? subsite.id : site.site_id;
    //data['selected_site'] = subsite ? subsite : site;
    data['task_type'] = taskType;

    //we need to send this as well to minimize the if conditions in the backend api
    if(taskType === "ssr") {
      data['drop_site'] = site.site_id;
    } 

    if(taskType === "delivery" && data['drop_site'] === "") {
      flash({
        active: true,
        message:
          "Une erreur est survenue, veuillez remplir les champs obligatoires.",
        classes: "warning",
        style: {},
      });
      notLoading();

    } else {
    
      api.post("/api/planning/assign", data).then((response) => {
        //console.log("hello", response);
        if (response.data.success) {
          flash({
            active: true,
            message: "Tâche planifié avec succès.",
            classes: "success",
            style: {},
          });

          if(typeof loadSites === "function")
            loadSites();

          close();
        } else {
          flash({
            active: true,
            message:
              "Une erreur est survenue, veuillez contacter l'administrateur.",
            classes: "warning",
            style: {},
          });
        }

        notLoading();
      })
      .catch(err => {
        console.log("hello", err);
      });
    }
  };

  //console.log("hello filters", props.filters);

  return (
    <div className={open ? "ui-overlay open" : "ui-overlay"}>
      <div
        className={
          "ui-overlay-content col-md-4 offset-md-4"
        }
      >
        <button
          onClick={close}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5>Planifier une tâche</h5>
        <form onSubmit={submitAssignForm}>
            <div className="form-group mt-3">
                <label className={"font-exp"}>Type</label>
                <Select
                    classNamePrefix={"custom-select"}
                    value={{label: taskType, value: taskType}}
                    options={[
                      {value:"delivery", label: "Delivery"},
                      {value:"ssr", label: "SSR"},
                      {value:"installation", label: "Installation"}
                    ]}
                    cacheOptions
                    defaultOptions
                    placeholder="Agent"
                    className={"d-block mt-1"}
                    disabled
                    isDisabled={true}
                />

          </div>
          
          {taskType === 'delivery' && (
            <>
          <div className="form-group mt-3">
            <label className={"font-exp"}>Drop site</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"drop_site"}
              /*defaultValue={{
                label: site.site_owner_agent || site.site_next_pm_agent || "",
                value: site.site_owner_agent_id || site.site_next_pm_agent_id || ""
              }}*/
              options={props.filters.sites}
              cacheOptions
              defaultOptions
              placeholder="Site"
              className={"d-block mt-1"}
            />
          </div>
        </>
        )}

          <div className={"form-group mt-3"}>
            <label className={"font-exp"}>Date</label>
            <input
              required
              defaultValue={site.site_next_pm}
              name="planning_date"
              type={"date"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label>Description</label>
            <textarea name="instruction" className={"form-control"} />
          </div>

          <div className="form-group mt-3">
            <label className={"font-exp"}>Select agent</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"agent"}
              defaultValue={{
                label: site.site_owner_agent || site.site_next_pm_agent || "",
                value: site.site_owner_agent_id || site.site_next_pm_agent_id || ""
              }}
              options={props.filters.agents}
              cacheOptions
              defaultOptions
              placeholder="Ingenieur"
              className={"d-block mt-1"}
            />
          </div>

          {taskType === 'cm' && (
            <>
          <div className="form-group mt-3">
            <label className={"font-exp"}>Select problem type</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"problem_type"}
              options={props.filters.problem_types}
              cacheOptions
              defaultOptions
              placeholder="Problem type"
              className={"d-block mt-1"}
            />
          </div>

          <div className="form-group mt-3">
            <label className={"font-exp"}>Select alarm type</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"alarm_type"}
              options={props.filters.alarm_types}
              cacheOptions
              defaultOptions
              placeholder="Alarm type"
              className={"d-block mt-1"}
            />
            </div>
            </>
          )}

          <div className="form-group mt-3">
            <input
              type="submit"
              value="Planifier"
              className={"btn btn-primary btn-sm mt-2"}
            />
          </div>

        </form>
        <div>
          <span className={"font-exp"}>Agent will receive a notification.</span>
        </div>
      </div>
    </div>
  );
};

export default PlanPmOverlay;
