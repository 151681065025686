import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const capacity = Array(30).fill(0);

const NotesReturn = ({ data, active, ...props }) => {

  //console.log("hello here", data);

  return (
    <>
     {data.cause_return ? data.cause_return : ''}

    </>
  );
};

export default NotesReturn;
