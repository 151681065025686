import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
  setOverlay,
} from "../../../Redux/actions/index";
import { Trans } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronDown,
  faUndo,
  faUser,
  faEdit,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
// import Pagination from "../../../Utils/Pagination";
// import SitesExport from "../../../Utils/SitesExport";
// import SiteRow from "./SiteList/SiteRow";
// import EditSiteOverlay from "./SiteList/EditSiteOverlay";
// import ChangeOwnerOverlay from "./SiteList/ChangeOwnerOverlay";
import SiteGPMRow from "./SiteGPMRow";
import PlanPmOverlay from "../../../Components/Addresses/SiteList/PlanPmOverlay";
import AssignSiteOverlay from "../../../Components/Addresses/SiteList/AssignSiteOverlay";
import ChangeOwnerOverlay from "../../../Components/Addresses/SiteList/ChangeOwnerOverlay";

const moment = require("moment");

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (user) => dispatch(setAuthAction(user)),
  setOverlay: (e) => dispatch(setOverlay(e)),
  doneLoading: () => dispatch(doneLoading()),
  isLoading: (p) => dispatch(startLoading(p)),
  setFlash: (f) => dispatch(setFlash(f)),
  closeFlash: (f) => dispatch(closeFlash(f)),
});

class SiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showSiteOverlay: false,
      showOverlay: false,
      siteid: "",
      agentid: "",
      date: "",
      selected_site: {},
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showOverlay: false });
    }
  };

  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
  }

  planSite = (site, e) => {
    this.setState({
      date: site.site_next_pm ? site.site_next_pm : "",
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showPlanOverlay: true
    });

  };

  assignSite = (site, e) => {
    this.setState({
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showAssignOverlay: true
    });
  };

  ownerSite = (site, e) => {
    this.setState({
      defaultOwner: site.owner ? site.owner : "",
      site: site,
      showOwnerOverlay: true
    });
  };



  editSite = (site) => {
    this.setState({
      showSiteOverlay: true,
      selected_site: site,
    });
  };

  render() {
    //const { t, i18n } = useTranslation();

    const data = this.props.data;
    const {showAssignOverlay, showPlanOverlay, showOwnerOverlay} = this.state;

    
    return (
      <React.Fragment>
        {showPlanOverlay && (
          <PlanPmOverlay
            close={() => this.setState({ showPlanOverlay: false })}
            loadSites={this.props.handlers.loadSites}
            open={true}
            site={this.state.site}
            taskType={"GPM"}
            {...this.props}
          />
        )}
        <div className="col-md-12 ctable mb-3">
          <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>
              
              <div className={"col-md-1"}>Code (physique)</div>

              <div className={"col-md-2"}>Zone name</div>
              <div className={"col-md-1"}>
                Nb GE
              </div>
              <div className={"col-md-2"}>Wilaya</div>

              <div className={"col-md-1"}>
                Owner
              </div>

              <div className={"col-md-2"}>Next plan</div>
              <div className={"col-md-1"}>Ingénieur</div>

              <div className={"col-md-2 task-details-col"}>Action</div>
            </div>
          </div>

          {data.sites.map((site, i) => (
            <SiteGPMRow
              role={this.props.user.role}
              key={i}
              site={site}
              selectSite={this.props.handlers.selectSite}
              editSite={this.editSite}
              reassignSite={this.assignSite}
              planSite={this.planSite}
              ownerSite={this.ownerSite}
              session={this.props.user}
            />
          ))}

          {data.sites.length === 0 && (
            <div className={"col-md-12 text-center my-3"}>
              Aucun site trouvé
            </div>
          )}



          {showAssignOverlay && (

            <AssignSiteOverlay
              close={() => this.setState({ showAssignOverlay: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              type={"gpm"}
              site={this.state.site}
              {...this.props}
            />
          )}
         

          {showOwnerOverlay && (
            <ChangeOwnerOverlay
              type={"gpm"}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              close={() => this.setState({ showOwnerOverlay: false })}
            />
          )}
          {/* <EditSiteOverlay
            loadSites={this.props.handlers.loadSites}
            open={this.state.showSiteOverlay}
            site={this.state.selected_site}
            close={() =>
              this.setState({ showSiteOverlay: false, selected_site: {} })
            }
            {...this.props}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteList);
