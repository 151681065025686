import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import api from "../../Utils/Api";
import { buildFilters } from "../../Utils/Helpers";
import { doneLoading, startLoading } from '../../Redux/actions/index';
//import AsyncSelect from 'react-select/lib/Async';

const mapStateToProps = state => {
	return {
		loading: state.ui.loading,
    filters: state.filters,
	}
}

function mapDispatchToProps(dispatch) {
  return {
    doneLoading: () => dispatch(doneLoading()),
    isLoading: (e) => dispatch(startLoading(e))
  };
}


class TaskNew extends Component {

  constructor(props) {

    super(props);

    this.props.isLoading({
			message:"Chargement",
			animate:true
		});

    this.state = {
      sites: [],
      filters: {},
    };

    this.loadSites();
  }

  loadSites() {

    this.props.isLoading();

    const crits = buildFilters(this.state.filters);
    api.get(`/api/sites/fetch/filter?limit=-1&${crits}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            sites: response.data.sites,
          });
          this.props.doneLoading();
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
            this.props.doneLoading();
          }
        }
      });
  }

  submitTask = (e) => {
    e.preventDefault();
    this.props.submitTask(e);
  }

  render() {

    //console.log("hello", this.state.sites);

    var electrified = [{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}];

    return (
      <React.Fragment>
        <div className={"container-header"}>Nouvelle zone</div>

        <form onSubmit={this.submitTask}>
          <div className={"row"}>
            <div className={"col-md-6 taskForm"}>

			         <div className={"form-group"}>
                <label>Site Owner </label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_owner"
                  options={this.props.filters.site_owners.filter(item => item.value !== "outsource" && item.value !== "")}
                />
              </div>

			        <div className={"form-group"}>
                <label><b>Code site physique</b></label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_physique"
                  options={this.state.sites.map(
                    (site) => ({label : site.site_code, value : site.site_id})
                  )}
                />
              </div>

            {/*  <div className={"form-group"}>
                <label><b>Site physique</b></label>
                <select
                  //classNamePrefix={"custom-select"}
                  //className="mt-4 col-md-8 col-offset-4"
                  style={{
                    fontSize: 13,
                    backgroundColor: 'rgb(252, 252, 252)',
                    borderColor: 'rgb(180, 180, 180)',
                    borderRadius: 2,
                    boxShadow: 'none',
                    minHeight: 0,
                    padding: 0,
                    minWidth: 70,
                    color: 'rgb(60,60,60)',
                    paddingLeft: 5,
                    paddingRight: 7,
                    width: '100%',
                    height: 32,
                  }}
                  name="site_physique">
                  {this.state.sites.map((site) => <option key={site.site_id} value={site.site_id}>{site.site_name}</option>)}
                </select>
              </div> */}

              <div className={"form-group"}>
                <label><b>Code facture</b></label>
                <input
				          required
                  type="text"
                  name="site_code_facture"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Mode</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_mode"
                  options={this.props.filters.gpm_modes}
                />
              </div>

              <div className={"form-group"}>
                <label>Zone</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_zone"
                  options={this.props.filters.gpm_zones}
                />
              </div>

             <div className={"form-group"}>
                <label>Electrified</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_electrified"
                  options={electrified.filter(
                    (item) => item.value
                  )}
                />
              </div>

              <div className={"form-group"}>
                <button
                  type="submit"
                  className={"btn btn-success float-right"}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskNew);
