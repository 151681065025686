const uiState = {
  appLoaded: false,
  notifications: [],
  notificationsHash: null,
  flashMessage: {
    active: false,
    message: "",
    style: {},
    classes: ""
  },
  stickyMessage: {
    content: null,
    type: null
  },
  loading: true,
  loadingMessage: {
    message: "Loading",
    animate: false
  },
  overlay: {
    enabled: false,
    message: "Testing",
    label: false,
    type: "success",
    button: {
      type: "button",
      label: "Test button label",
      link: "http://google.com"
    }
  }
};

const animateLoading = dispatch => {
  const that = this;
  this.animationInterval = setInterval(function() {
    if (that.state.loadingAnimate.length !== 3) {
      that.setState({ loadingAnimate: that.state.loadingAnimate + "." });
    } else {
      that.setState({ loadingAnimate: "" });
    }
  }, 600);
};

export default (state = uiState, action) => {
  if (action.type === "APP_LOADED") {
    return {
      ...state,
      loading: false,
      appLoaded: true
    };
  } else if (action.type === "DONE_LOADING") {
    return {
      ...state,
      loading: false,
      loadingMessage: {
        message: "Loading",
        animate: false
      }
    };
  } else if (action.type === "IS_LOADING") {
    return {
      ...state,
      loading: true,
      loadingMessage: {
        message: action.payload ? action.payload.message : state.loadingMessage.message,
        animate: action.payload ? action.payload.animate : state.loadingMessage.animate
      }
    };
  } else if (action.type === "SET_OVERLAY") {
    if (action.payload) {
      return {
        ...state,
        overlay: { ...action.payload, enabled: true }
      };
    } else {
      return {
        ...state,
        overlay: {
          enabled: false
        }
      };
    }
  } else if (action.type === "SET_FLASH") {
    console.log("SET_FLASH");
    return {
      ...state,
      flashMessage: action.payload
    };
  } else if (action.type === "CLOSE_FLASH") {
    return {
      ...state,
      flashMessage: {
        ...state.flashMessage,
        active: false
      }
    };
  } else if (action.type === "UPDATE_STICKY") {
    return {
      ...state,
      stickyMessage: {
        content: action.payload
      }
    };
  } else {
    return state;
  }
};
