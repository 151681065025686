import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TasklistGlobalContainer from "../Containers/TasklistGlobalContainer";




function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class TasklistRouter extends Component {
  render() {
    return (
      <Switch>

           
       
            <Route exact path="/task_list" component={TasklistGlobalContainer} />
 
          

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(TasklistRouter);