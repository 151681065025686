import React, { useState } from "react";
import api from "../../../Utils/Api";
import Constants from "../../../Utils/Constants";
import { faArrowUp, faSpinner, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

const NewFile = ({close, after, data = {}, ...props}) => {


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");


    const editMode = Boolean(data.id);

    const submitForm = (e, success) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        const data = new FormData(e.target);
        api.post(e.target.action, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            if(res.data.error)  
                setError(res.data.error);

            setLoading(false);

        })
        .catch(err => {
            setLoading(false);
            setError("Une erreur est survenu, veuillez contactez l'administrateur.");

            
        }).then(() => after());
    }

    const platforms = [
        {label: "Web", value: "web"},
        {label: "Mobile", value: "mobile"}
    ];

    const scopes = [
        {label: "PM", value: "pm"},
        {label: "GPM", value: "gpm"}
    ];

    const roles = [
        {label: "Ingénieur", value: "agent"},
        {label: "Manager", value: "manager"},
        {label: "Cluster Manager", value: "cluster_manager"}
    ]


    
    return (
        <>

            <div className={"ui-overlay open"}>
                <div className={"ui-overlay-content import-overlay col-md-8"}>
                    <button
                    onClick={() => close()}
                    className={"ui-overlay-close btn btn-danger btn-sm"}
                    >
                <FontAwesomeIcon icon={faTimes} />
                </button>


                <form onSubmit={submitForm} action={Constants.apiURL+"/api/settings/files/"+(editMode ? "edit" : "new")} method="post">
                    
                    {editMode && <input type="hidden" value={data.id} name="id" />}
                    
                    <div className={"form-group mt-5"}>
                        <label>Fichier PDF</label>
                        {editMode ? <input  type="input" disabled className={"form-control"} value={data.url} /> : <input  type="file" name="file" className={"form-control"} accept={"application/pdf"} />}
                    </div>
                    
                    <div className={"form-group"}>
                        <label>Accès par Platform</label>
                        <Select
                            name={"access_platform[]"}
                            classNamePrefix={"custom-select"}
                            defaultValue={data.platform_access && platforms.filter(item => data.platform_access.indexOf(item.value) > -1)}
                            options={platforms}
                            isMulti={true}
                        />
                    </div>

                    <div className={"form-group"}>
                        <label>Accès par Role</label>
                        <Select
                            name={"access_role[]"}
                            classNamePrefix={"custom-select"}
                            defaultValue={data.role_access && roles.filter(item => data.role_access.indexOf(item.value) > -1)}
                            options={roles}
                            isMulti={true}
                        />
                    </div>

                    <div className={"form-group"}>
                        <label>Accès par Scope</label>
                        <Select
                            name={"access_scope[]"}
                            classNamePrefix={"custom-select"}
                            defaultValue={data.scope_access && scopes.filter(item => data.scope_access.indexOf(item.value) > -1)}
                            options={scopes}
                            isMulti={true}
                        />
                    </div>



                    <div className={"form-group"}>
                        <label>Description</label>
                        <textarea name={"description"} className={"form-control"}>{data.description}</textarea>
                    </div>



                    <div className={"form-group"}>
                        <button disabled={loading} type="submit" className={"btn btn-success float-right"}>
                            {loading ? <FontAwesomeIcon icon={faSpinner}  spin={true} /> : (<><FontAwesomeIcon icon={editMode ? faCheck : faArrowUp} className={"mr-2"} />Envoyer</>)}
                        </button>
                    </div>

                </form>

                </div>
            </div>
        </>
    )
}

export default NewFile;