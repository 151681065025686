import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";
const Drop = ({ data,active ,...props }) => {
    if (data)
    return (
      <>
<RenderChecks data={data} />

    <b>BarCodes :</b>
          <br />
          {data.myBarCode && <div >

                {data.myBarCode.map(item=>(
                  <div className={"row "} key={item}>{item}</div>
                ))}

          </div>}
          
    <b>Photos</b>
            <br />
            {data.photos && (
              <div className={"test-photos-wrapper"}>
                {data.photos.map((photo, key) => (
                  <div key={"clock_photo_"+key} className={"test-photos-single"}>
                    <PhotoItem photo={photo} active={1} />
                  </div>
                ))}
              </div>
            )}
  
        </>
    );

};

export default Drop;
