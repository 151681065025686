import React from "react";
import { useSelector } from "react-redux";


const StickyMessage = () => {

    const {stickyMessage} = useSelector(state => ({stickyMessage: state.ui.stickyMessage }) );

    if(!stickyMessage.content)
        return <></>;   

    return (<>
        <div className={"flash-alert active"}>
            <div className={"alert alert-success sticky-message"}> 
                <span>{stickyMessage.content}</span>
            </div>
        </div>
    </>);
}

export default StickyMessage;