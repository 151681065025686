import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const Transmission = ({ data, active = true, ...props }) => {
    return (



        <div >
            <div className={"test-photos-wrapper"}>
                <table className={"table"}>

                    <tr>
                        <th>- Photo Antenne Avant Swap</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 0
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 1
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Configuration Radio Link selon PQ</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 3
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Test D’interférence avec la nouvelle configuration</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 4
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 5
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- RSL</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Test Xpic</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 8
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 9
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Nouvelle antenne Near end 16050</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 10
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etiquetage Outdoor Near end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 11
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etanchéité Connecteur Near end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 12
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>-  Etiquetage Outdoor Near end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 13
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Mise à la terre Near end(RAU + coax)</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 14
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etiquetage Indoor Near end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {/* {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {data.photos.map((photo, key) => (
                                    key === 15
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Nouvelle antenne Far end 353013</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 16
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etanchéité Connecteur Far end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 17
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etiquetage Outdoor Far end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 18
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Mise à la terre Far end(RAU + coax)</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 19
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Etiquetage Indoor Far end</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 20
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Performance de deux heures Minimum</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 21
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 22
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- RSL</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- RSL</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    key === 6
                                    &&
                                    <div>
                                        <h6>Far End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 7
                                    &&
                                    <div>
                                        <h6>Near End</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                </table>

            </div>


        </div>
    );
};

export default Transmission;
