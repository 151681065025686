const { user, auth } = localStorage.user_session
  ? JSON.parse(localStorage.user_session)
  : { user: {}, auth: false };

const sessionState = {
  auth: false,
  user: {}
};





export default (state = sessionState, action) => {
  if (action.type === "SET_AUTH") {
    const userSession = action.payload;

    let newState = {
      ...state,
      auth: action.payload.success,
      user: userSession,
    };
    localStorage.setItem(
      "user_session",
      JSON.stringify({ auth: action.payload.success, user: userSession })
    );

    console.log(newState)
    // if (!action.payload.stay) window.location.href = "/login";

    return newState;
  }

  return state;
};
