import React, { Suspense } from 'react';
import Sites from './GPM/Sites';
import Tasks from './GPM/Tasks';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoadingOverlay from '../../Common/LoadingOverlay';
import Agents from './GPM/Agents';


const AdminComp = React.lazy(() => import(/* webpackChunkName: "adminDashbaord" */"../GPMAdminDashboard"));

class GPMDashboardWrapper extends React.Component {


  render() {


    return (

        <>
        <div className={"page-menu dashboard"}>
            <ul>
                <li><NavLink to={'/gpm/dashboard/sites'}>Sites</NavLink></li>
                <li><NavLink to={'/gpm/dashboard/tasks'}>Plan & Execution</NavLink></li>
                <li><NavLink to={'/gpm/dashboard/equip'}>Equipement & matériel</NavLink></li>
                <li><NavLink to={'/gpm/dashboard/agents'}>Ingenieurs</NavLink></li>
            </ul>
        </div>

        <div>
            <Switch>

                <Suspense  fallback={<LoadingOverlay active={true} />}>
                    <Route exact path="/gpm/dashboard" component={Sites} />
                    <Route exact path="/gpm/dashboard/sites" component={Sites} />
                    <Route exact path="/gpm/dashboard/tasks" component={Tasks} />
                    <Route exact path="/gpm/dashboard/equip" component={AdminComp} />
                    <Route exact path="/gpm/dashboard/agents" component={Agents} />
                </Suspense>

            </Switch>
        </div>
        </>
      );
  }


}

export default GPMDashboardWrapper;
