import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../Utils/MoreInfoDialog';
import moment from 'moment';
import CheckOrTime from './components/CheckOrTime';
import Restricted from '../../Utils/Restricted';
import api from '../../Utils/Api';
import DeliveryTests from './Delivery/Tests/index';
import RenderComponent from '../../Utils/RenderComponent';
import Constants from '../../Utils/Constants';
import ApiService from '../../Utils/ApiService';
import { useSelector } from 'react-redux';
const TaskGlobalRow = ({task, key, deleteTask, returnTask, closeTask, ...props}) => {
    const {session} = useSelector(state => ({session: state.session}));
    const [activeTest, selectTest] = useState("clockin");
    const [showDetail, setShowDetail] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
    const selectTask = (show = true) => {

                  if(!showDetail) {
                    setLoading(true);
                    ApiService.fetchTaskDetail(task.task_id)
                    .then(res => {
                      if(res.data.success && res.data.total > 0) {
                        setDataLoaded(true);
                        setData(res.data.tasks[0]);
                      }
                      setLoading(false);
                    })
                  }
                  setShowDetail(!showDetail)
      }
    const exportPM = (anomalies) => {

        setLoading(true);
            if(anomalies) {
          exportForm.current.action = Constants.apiEndPoints.EXPORT_TASK_ANOMALIES(task.task_id);
            } else {
                exportForm.current.action = Constants.apiEndPoints.EXPORT_TASK(task.task_id);
            }

        if(!dataLoaded)
          ApiService.fetchTaskDetail(task.task_id)
          .then(res => {
            if(res.data.success && res.data.total > 0) {
              setDataLoaded(true);
              setData(res.data.tasks[0]);
              detailInput.current.value = JSON.stringify(res.data.tasks[0]);
              exportForm.current.submit();
              setLoading(false);
            }
            setLoading(false);
          })
        else {
          detailInput.current.value = JSON.stringify(data);
          exportForm.current.submit();
          setLoading(false);
        }
      }
      const stepsDone = Object.keys(task.task_tests).filter((el) => el !== "clockin" && task.task_tests[el].data && task.task_tests[el].data.done);
   return(
       <React.Fragment>
           <form method="post" ref={exportForm} target={"_blank"} >
                 <input type="hidden" name={"pm"} ref={detailInput} />
                 <input type="hidden" name={"token"} value={session.user.token} />
            </form>
            <div className={"row "}>
                            <div className={"col-md-12"}>
                                <div className={"row ctable-row"}>
                                  <div className={"col-md-1 table-space-between"}>
                                       <span>{task.task_id}</span>
                                       {!task.task_status ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} /> : task.task_status === "return" ? <FontAwesomeIcon color="orange" icon={faUndo} /> : <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />}
                                  </div>
                                  <div className={"col-md-1"}>
                                        <a className={"site-link"} href={"/agents/"+task.task_agent_id}>{task.task_agent}</a>
                                  </div>
                                  <div className={"col-md-1"}>
                                      {task.task_type}

                                  </div>
                                  <div className={"col-md-2"}>
                                       <span className={"task-plan-col"}>
                                         <span className={"line task-"+task.task_delay}>
                                           <span className={"line-label"}>Créé</span>
                                           <span className={"line-value"}>{task.task_created}</span>
                                         </span>
                                         <span className={"line"}>
                                           <span className={"line-label"}>Plan</span>
                                           <span className={"line-value"}>{task.task_plan}</span>
                                         </span>
                                       </span>
                                  </div>

                                  <div className={"col-md-2 task-tests-col"}>
                                        <span>{stepsDone.length+"/2"}</span>
                                        {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.length} anomalies</span> : ""} */}
                                        {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.filter(el => el.resolved).length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.filter(el => el.resolved).length} résolues </span> : ""} */}
                                  </div>
                                  <div className={"col-md-1"}>
                                      {task.pickup_site_name}

                                  </div>
                                  <div className={"col-md-1"}>
                                      {task.drop_site_name}

                                  </div>

                                  <div className={"col-md-3 task-details-col"}>
                                       {task.geo && task.geo.lat && (
                                         <a href={"https://www.google.com/maps/place/"+task.geo.lat+","+task.geo.lng+"/@"+task.geo.lat+","+task.geo.lng+",15z"} target='_blank' className="task-action-btn" >
                                           <FontAwesomeIcon icon={faMapMarker}  />Localisation
                                         </a>
                                       )}

                                               <button onClick={() => selectTask(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={isLoading ? faSpinner : faChevronDown} spin={isLoading}  /> Info</button>

                                                 <MoreInfoDialog title={"Plus"}>

                                                   <Restricted scope={"pm"} permission={"export_task"}>
                                                       <Item onClick={() => exportPM() }  className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  /> Export</Item>
                                                   </Restricted>

                                                   <Restricted scope={"pm"} permission={"delete_task"}>
                                                       <Item onClick={() => deleteTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash}  /> Supprimer</Item>
                                                   </Restricted>

                                                   <Restricted scope={"pm"} permission={"close_task"}>
                                                       <Item onClick={() => closeTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faLock}  />Cloturer</Item>
                                                   </Restricted>

                                                   <Restricted scope={"pm"} permission={"return_task"}>
                                                       <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo}  />Retourner</Item>
                                                   </Restricted>

                                                   <Restricted scope={"pm"} permission={"read_task"}>
                                                       <Item onClick={() => exportPM(true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  />Export NOK</Item>
                                                   </Restricted>
                                                 </MoreInfoDialog>
                                             </div>
                                           </div>
                                           {showDetail && dataLoaded && data.task_tests && (
                         <div className={"animateRow row"}>
                           <div className={"pm-wrapper col-md-12 row no-gutters"}>
                             <div className={"col-md-3 pm-sidebar"}>
                               <ul>
                                 {DeliveryTests.map(test => (
                                   <li className={activeTest === test.key ? "active" : ""}>
                                     <a onClick={e => selectTest(test.key)}>
                                       <CheckOrTime   task={task.task_tests[test.key]} />
                                       {test.name} <FontAwesomeIcon icon={faAngleRight} size={22} />
                                     </a>
                                   </li>
                                 ))}
                                 </ul>
                               </div>

                               <div className={"col-md-9 pm-content"}>
                                 {DeliveryTests.map(test => data.task_tests[test.key] && data.task_tests[test.key].data && data.task_tests[test.key].data.done && activeTest === test.key && (
                                   <div className={"pm-test-single"}>
                                     <h5>{test.name} {data.task_tests[test.key].data.finished_at ? <b>{moment(data.task_tests[test.key].data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                                     <RenderComponent component={test.component} data={data.task_tests[test.key].data} />
                                   </div>
                                 ))}
                               </div>
                           </div>
                         </div>
                         )}
                     </div>
             </div>



       </React.Fragment>
   )
}
export default TaskGlobalRow;
