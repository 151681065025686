import React from "react";


const CMActions = ({data}) => {



    return (
        <div>
            <ul>
                {data.items && data.items.map(action => (
                    <li>
                        <label>{action}</label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CMActions;