import React, { Component } from "react";
import set from "lodash/set";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../../Components/Task/Delivery/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import Restricted from "../../Utils/Restricted";
import { Container } from '../../../Container';

import PlanPmOverlay from "../../Components/Addresses/SiteList/PlanPmOverlay";


const mapStateToProps = state => {
  return {
    session: state.session,
    filters: state.filters
  };
};


function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
};

class TaskListContainer extends Component {

  constructor(props) {

    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true
    });

    this.state = {
      taskid: null,
      total: 0,
      managers: [],
      tasks: [],
      agents: this.props.filters.agents,
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {
        task_type: 'delivery'
      }
      // visible:false,//cerine
      // showSiteOverlay: false,//cerine
      // showOverlay: false,//cerine
      // siteid: "",//cerine
      // selected_site: {},//cerine
    };
    console.log('hollo ',this.props.filters.agents)
    // this.toggleOverlay = this.toggleOverlay.bind(this);//cerine

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this)
    };

  };
  // toggleOverlay(e) {
  //   this.setState({ showOverlay: !this.state.showOverlay });
  // };//cerine
  // planSite = (site, e) => {
  //   this.setState({
  //     date: site.site_next_pm ? site.site_next_pm : "",
  //     agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
  //     agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
  //     siteid: site.site_id,
  //     site: site,
  //     showPlanOverlay: true
  //   });

  // };//cerine

  // planSite = (site, e) => {
  //   this.setState({
  //     date: site.site_next_pm ? site.site_next_pm : "",
  //     agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
  //     agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
  //     siteid: site.site_id,
  //     site: site,
  //     showPlanOverlay: true
  //   });

  // };//cerine

  // assignSite = (site, e) => {
  //   this.setState({
  //     agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
  //     agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
  //     siteid: site.site_id,
  //     site: site,
  //     showAssignOverlay: true
  //   });
  // };//cerine

  // ownerSite = (site, e) => {
  //   this.setState({
  //     defaultOwner: site.owner ? site.owner : "",
  //     site: site,
  //     showOwnerOverlay: true
  //   });
  // };//cerine
  // editSite = (site) => {
  //   this.setState({
  //     showSiteOverlay: true,
  //     selected_site: site,
  //   });
  // };//cerine
  changeLimit = e => {

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  updateFilter = (event, name) => {

    const value = event.target ? event.target.value : event.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.isLoading();
        this.loadTasks(1);
      }
    );
  };

  componentDidMount() {

    this.setState({
      agents: this.props.filters.agents
    })
    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();
  }

  componentDidUpdate(prev) {

    // if(this.props.match.params.taskid && this.props.match.params.taskid) {
    // 	this.setState({
    // 		taskid: this.props.match.params.taskid
    // 	})
    // }
  }

  loadTasks(p = this.state.current) {

    let nstate = this.state;
    let that = this;
    let crits = buildFilters(this.state.filters);

    api.get(`/api/task/fetch?${crits}&page=${p}&limit=${this.state.limit}`).then(function(response) {
      if (response.data.success) {
        response.data.tasks.map(() => {
          nstate.rows.push({ hidden: true });
        });
        that.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          rows: nstate.rows,
          total: response.data.total
        });

      } else {
        if (response.data.errcode === 101) {
          that.props.setAuth(false);
        }
      }

      that.props.doneLoading();
    });

  }

  exportAnomalies = e => {

    if (window.ga) window.ga("send", "event", "TaskList", "exportAnomalies");

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + `/api/task/fetch?${crits}&limit=-1&anomalies`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = e => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + `/api/task/fetch?${crits}&limit=-1&export=true`;
    /////////////////////////SAM
    // const g = 'igeneral';
    // const c = 9123;
    // const url = Constants.apiEndPoints.EXPORT_TASK(c);
    // // const url = remote + `/api/delivery/export/${g}/${c}`;
    ////////////////////////SAM
    console.log(url)
      // const url=`http://localhost/netfersmartsolutions-witrack-rollout-backend-984317795bff/public/api/task/fetch?${crits}&limit=-1&export=true`
    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };


  selectPage = p => {

    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  render() {
    // const { showPlanOverlay} = this.state;
    // const App = () => {
    //   const triggerText = ' Ajouter une tache';
    //   const onSubmit = (event) => {
    //     event.preventDefault(event);
    //     console.log(event.target.name.value);
    //     console.log(event.target.email.value);
    //   };
  //   const triggerText = 'Ajouter une tache';
  // const onSubmit = (event) => {
  //   event.preventDefault(event);
  //   console.log(event.target.type.value);
  //   console.log(event.target.site.value);
  //   console.log(event.target.date.value);
  //   console.log(event.target.ag.value);
  //   console.log(event.target.p.value);
  //   console.log(event.target.al.value);
  // };
    return (
      <React.Fragment>

        <div className={"container-header"}>
          Delivery Tasks
          {!this.props.session.auth ? <Redirect to="/login" /> : ""}
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {/* <form action="" method="GET"  onSubmit={e => this.exportAnomalies(e)} >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}>Exporter les anomalies <FontAwesomeIcon className={"ml-2"} icon={faFileExport} /></button>
						</form> */}
            {/* cerine */}
              {/* <form action=""  >
                <input type="hidden" name="token" value={this.props.session.user.token} />
                <button type="submit"  className={"btn btn-primary btn-block mb-2"}>


         Ajouter une tache

           </button>
               </form> */}

              {/* <Container triggerText={triggerText} onSubmit={onSubmit} /> */}

              <form action="" method="POST" onSubmit={e => this.exportPMS(e)}>
                <input type="hidden" name="token" value={this.props.session.user.token} />
                {/* SAM EXPORT */}
                <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                  Exporter la liste SAM
                </button>
              </form>

              <h4>Recherche</h4>

              <Restricted role={"manager"}>
                <div className={"form-group"}>
                  <label>Site owner</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.site_owners]}
                    placeholder="O&M, Sous-traitant..."
                    onChange={e => this.updateFilter(e, "site_owner")}
                  />
                </div>
              </Restricted>

              <Restricted role={"cluster_manager"}>
                <div className={"form-group"}>
                  <label>Demandeur </label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.managers]}
                    placeholder="Demandeur"
                    onChange={e => this.updateFilter(e, "site_manager")}
                  />
                </div>
              </Restricted>

              {/*<div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.clusters]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "site_cluster")}
                />
              </div>*/}

              <div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  classNamePrefix={"custom-select"}
                options={[{ label: "Tous", value: "" }, ...this.props.filters.wilayas]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "site_wilaya")}
                />
              </div>

              {this.props.session.user.role !== "agent" && (
                <div className={"form-group"}>
                  <label>Ingenieur</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.agents]}
                    placeholder="Ingenieur"
                    onChange={e => this.updateFilter(e, "agent")}
                  />
                </div>
              )}

              <div className={"form-group"}>
                <label>Date début</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_from")} />
              </div>

              <div className={"form-group"}>
                <label>Date fin</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_to")} />
              </div>

              <div className={"form-group"}>
                <label>Sites</label>
                <input
                  name="site"
                  className={"form-control"}
                  placeholder="Nom ou code site"
                  value={this.state.filters.site}
                  onChange={e => this.updateFilter(e, "site")}
                />
              </div>

              <div className={"form-group"}>
                <label>ID Delivery</label>
                <input
                  type={"text"}
                  name="pm"
                  className={"form-control"}
                  onKeyUp={e => this.updateFilter(e, "pm")}
                  defaultValue={this.state.filters.pm}
                />
              </div>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Delivery trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <TaskList
              data={this.state}
              rows={this.state.rows}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <TaskListContainer {...props} socket={socket} />}</SocketContext.Consumer>
)
export default connect(mapStateToProps, mapDispatchToProps)(TaskListConsumer);

