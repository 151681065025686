import axios from "axios";
import { connect } from "react-redux";
import store from "./../Redux/store/index";
import Constants from "./Constants";

const api = axios.create({
  baseURL: Constants.apiURL,
});

api.interceptors.request.use(
  function(config) {
    const state = store.getState();
    if (state.session.auth && state.session.user.token) {
      config.headers.common["X-STS-Token"] = state.session.user.token;
    }
    
    // console.log(state)
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);


export const nodeService = axios.create({
  baseURL: "http://localhost:85"
  // baseURL: "http://localhost:3000"
})


export default api;
