import React from "react";
import { Trans } from "react-i18next";
import api from "../../Utils/Api";
import { Redirect, Link } from 'react-router-dom';


export default class NOKRow extends React.Component {


    selectMulti = (id) => {
		this.props.toggleSelectSingleAnomalie(id);
	}

    render() {
        const anomalie = this.props.data;
        return (
            <>
			<div className={"row ctable-row"+ (this.props.selectMode ? " row-select-mode" : "") + (this.props.selected ? " row-selected" : "")} onClick={() => this.props.selectMode ? this.selectMulti(anomalie.id) : false}>

                    <div className={"col-md-3"}>
                        {this.props.data.type}
                    </div>

                    <div className={"col-md-1"}>
                        {this.props.data.status.toUpperCase()}
                    </div>
                    
                    <div className={"col-md-3"}>

                        {this.props.data.check}
                    </div>

                    <div className={"col-md-2"}>
                        {this.props.data.comment}
                    </div>
                    
                    <div className={"col-md-2 site-plan-col-date"}>
					
						<span><Link to={'/task/'+this.props.data.task.id} className={"site-link"}>{this.props.data.task.created_at}</Link></span>
						<span><Link to={"/task"} className={"site-link"}>{this.props.data.site.name + " ("+this.props.data.site.code+")"}</Link></span>
						<span>{this.props.data.task.created_by.username}</span>
					</div>
                    

                    <div className={"col-md-1 task-details-col"}>
                        <Trans i18nKey="label_details" />
                    </div>

                </div>

            </>
        )
    }
}