import React, { Component } from 'react';
import { Route, Redirect } from 'react-router'

export default class HomeView extends Component {
	render() {

		return this.props.auth 
					// ? <Redirect to={"/delivery"} /> 
					// ? <Redirect to={"/taches"} /> 
					? <Redirect to={"/ssr"} /> 
					: <Redirect to={"/login"} />
			 
			
	}
}

