import React, { useContext } from "react";
import { FiltersContext } from "../FiltersWrap";

const TextFilter = ({ label, name, ...props }) => {
  const context = useContext(FiltersContext);
  return (
    <div className={"form-group"}>
      <label>{label}</label>
      <input
        onChange={(e) => context.updateFunc(e, name)}
        name={name}
        type="text"
        className={"form-control"}
      />
    </div>
  );
};

export default TextFilter;
