import React,{Component} from 'react';
export default class Failure extends Component {
 render(){
     return(
           
        <div class="alert alert-danger" role="alert">
         { this.props.message ? this.props.message :  'Erreur de plannification !!! veuillez consulter  l\'administrateur' }
      </div>


     );
 }



}