import React from "react";

export const FiltersContext = React.createContext();



const FiltersWrap = ({update, session, children, ...props}) => {
    return (
        <FiltersContext.Provider value={{updateFunc: update, session: session}}>
            {children}
        </FiltersContext.Provider>
    )
}
export default FiltersWrap