import React, { Suspense } from 'react';
import Sites from './Delivery/Sites';
import Tasks from './Delivery/Tasks';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoadingOverlay from '../../Common/LoadingOverlay';
import Agents from './Delivery/Agents';


const AdminComp = React.lazy(() => import(/* webpackChunkName: "adminDashbaord" */"../AdminDashboard"));

class DashboardWrapper extends React.Component {
  

  render() {


    return (

        <>
        <div className={"page-menu dashboard"}>
            <ul>
                <li><NavLink to={'/dashboard/pm/sites'}>Sites</NavLink></li>
                <li><NavLink to={'/dashboard/pm/tasks'}>Plan & Execution</NavLink></li>
                {/* <li><NavLink to={'/dashboard/pm/equip'}>Equipement & matériel</NavLink></li> */}
                <li><NavLink to={'/dashboard/pm/agents'}>Ingenieurs</NavLink></li>
            </ul>
        </div>

        <div>
            <Switch>
                
                <Suspense  fallback={<LoadingOverlay active={true} />}>
                    <Route exact path="/dashboard" component={Sites} />
                    <Route exact path="/dashboard/pm/sites" component={Sites} />
                    <Route exact path="/dashboard/pm/tasks" component={Tasks} />
                    <Route exact path="/dashboard/pm/equip" component={AdminComp} />
                    <Route exact path="/dashboard/pm/agents" component={Agents} />
                </Suspense>
              
            </Switch>
        </div>
        </>
      );
  }

  
}

export default DashboardWrapper;

