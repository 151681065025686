import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";

export default class GuestTopMenu extends Component {
	render() {
		return (
			<ul className="header-menu">					
				<li><NavLink exact strict to="/"><Trans i18nKey="label_home" /> </NavLink></li>
				<li><NavLink exact to="/login"><Trans i18nKey="label_login" /> </NavLink></li>
				{/* <li><NavLink to="/register"><Trans i18nKey="label_register" /> </NavLink></li> */}
			</ul>
		)
	}
}
