import React from 'react';
import api from '../../Utils/Api';
import { connect } from 'react-redux';
import debounce from "lodash/debounce";


import Constants from '../../Utils/Constants';
import ApiService from '../../Utils/ApiService';


class Notifications extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue'
    };
  }

  playAudio = () => {
    import("./../../../lib/plucky.mp3").then(audio => {
      const res = new Audio(audio.default)
      res.play();
    })
  }

  displayNotification(notification) {
    this.setState({
      inlineNotification: notification[0],
      inlineNotificationActive: true,
    });

    const that = this;

    setTimeout(function() {
      that.setState({ inlineNotificationActive: false });
    }, 5000);
  }


  getNotifications = async () => {
	    
	const response = await ApiService.fetchNotifications();
      if (response.data.success) {
          if (response.data.hash !== this.props.notifications.notificationsHash) {
              this.props.updateNotifications(response.data);
          }
      }
}



  notificationsPoll() {
    const that = this;
    const fetchNotifications = debounce(this.getNotifications, 150);

    let remote = Constants.apiURL;

    if (this.props.auth) {
      //fetchNotifications();

      debounce(() => {
        var source = new EventSource(
          remote +
            "/api/notification/stream?hash=" +
            this.state.notificationsHash +
            "&token=" +
            that.props.user.token
        );

        const handleMessage = function(event) {
          const resp = JSON.parse(event.data);

          if (resp.success & (resp.success === true)) {
            that.getNotifications().then(() => that.playAudio());
          }

          if (resp.deb === 2) {
            var newSource = new EventSource(
              remote +
                "/api/notification/stream?hash=" +
                that.state.notificationsHash +
                "&token=" +
                that.props.user.token
            );
            newSource.onmessage = handleMessage;
            event.target.close();
          }
        };

        source.onmessage = handleMessage;
      }, 300)();
    }
  }



  render() {
    return (
        <div
        className={
            this.props.notifications.inlineNotification.active
            ? "notification-wrapper active"
            : "notification-wrapper"
        }
        >
        <div className={"notification-body"}>
            <span className={"notification-content"}>
            {this.props.notifications.inlineNotification.content
                ? this.props.notifications.inlineNotification.content
                : ""}
            </span>
        </div>
        </div>
    );
  }

}

const mapState = state => {
    return {
        session: state.session,
        ui: state.ui,
        notifications: state.notifications
    }
}
export default connect(mapState)(Notifications);
