import api from "./Api";

const getNotifications = (token = this.props.user.token) => {

  return api.get("/api/notification/fetch?token=" + token).then(response => {
    if (response.data.success) {
      if (response.data.hash !== this.props.notificationsHash) {
        this.props.updateNotifications(response.data);
      }
    } else {
      if (response.data.errcode === 101) this.props.setAuth(false);
    }
  });
};

export default getNotifications;
