import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const Trigger = ({ triggerText, buttonRef, showModal,closeModal }) => {
  return (
    <button 
      style={{}}
      className="btn  btn-primary btn-block mr-5"
      ref={buttonRef}
      onClick={showModal}
      onSubmit={closeModal}
    >
      <FontAwesomeIcon className={"dialog-icon"} icon={faPlus}  /> 
      &nbsp;
      {triggerText}
      
    </button>
  );
};
export default Trigger;
