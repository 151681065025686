import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const SiteAccess = ({ data, active = true, ...props }) => {
  return (
    <div>

      <h6>Données du site</h6>

      <div className={"row col-md-6"}>
        <label>Code site : </label>
        {data.site.site_code}
      </div>

      <div className={"row col-md-6"}>
        <label>Nom du site : </label>
        {data.site.site_name}
      </div>

      <div className={"row col-md-6"}>
        <label>GPS : </label>
        {data.site.site_address_position}
      </div>

      <div className={"row col-md-6"}>
        <label>Adresse du site : </label>
        {data.site.site_address_info}
      </div>

      {data.site.Subcontractor &&<div className={"row col-md-6"}>
        <label>Subcontractor : </label>
        {/*data.site.ssr_start_date*/data.site.Subcontractor}
      </div>} 



      <div className={"test-photos-wrapper"}>
      <table className={"table"}>

          <tr>
            <th>ACCÈS AU SITE</th>
            {data.photos && (
            <div className={"test-photos-wrapper"}>
              {data.photos.map((photo, key) => (
                key === 0
                &&
                <PhotoItem photo={photo} active={active} />
              ))}
            </div>
            )}
          </tr>

          <tr>
            <th>Site access view</th>
            {data.photos && (
            <div className={"test-photos-wrapper"}>
              {data.photos.map((photo, key) => (
                key === 1
                &&
                <PhotoItem photo={photo} active={active} />
              ))}
            </div>
            )}
          </tr>

          <tr>
            <th>GENERAL site VIEW</th>
            {data.photos && (
            <div className={"test-photos-wrapper"}>
              {data.photos.map((photo, key) => (
                key === 2
                &&
                <PhotoItem photo={photo} active={active} />
              ))}
            </div>
            )}
          </tr>


      </table>

      </div>
    
      

      
    </div>
  );
};

export default SiteAccess;
