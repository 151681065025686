import React from "react"
import {connect} from "react-redux"
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import { Link } from 'react-router-dom';

import api from '../../Utils/Api';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}


const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}


class AgentActivity extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			status: null,
			fullname: null,
			tasks: null,
			lastonline: null,
			stores: null,
			visits: null,
			logs: [],
			total_tasks_month: 0,
			data: {}
		}


		props.isLoading();

	}

	componentDidMount() {

		const that = this;

		api.get('/api/user/activity/agent/'+this.props.match.params.userid)
		.then(function(response) {
			if(response.data.success) 
				that.setState({...response.data})

			
		}).then(r => that.props.doneLoading()); 
	}

    render() {
        return (
		<>
		

		<div className={"row mt-2"}>
			<div className={"col-md-12"}>
				<h5 className={"text-center"}>Activité récentes</h5>

				<table className={"table offset-md-4 col-md-4"}>

				{this.state.logs.map(item => (
					<tr>
						<td>{item.created_at}</td>
						<td style={{textAlign: "right"}}>{item.action}</td>
					</tr>	
				))}
				</table>

				
			</div>	
        </div>
		</>
		)

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentActivity)