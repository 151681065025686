import React, { useContext } from "react";
import SelectFilter from "./Elements/SelectFilter";
import TextFilter from "./Elements/TextFilter";
import { FiltersContext } from "./FiltersWrap";
import RadioFilter from "./Elements/RadioFilter";
import DateFilter from "./Elements/DateFilter";

const FilterElement = ({
  label,
  name,
  options,
  type,
  defaultValue,
  role,
  ...props
}) => {
  const context = useContext(FiltersContext);

  if (context.session && role) {
    if (Array.isArray(role)) {
      if (role.indexOf(context.session.role) == -1) return <></>;
    } else {
      if (role !== context.session.role) return <></>;
    }
  }

  switch (type) {
    case "options":
      return (
        <SelectFilter
          defaultValue={defaultValue}
          options={options}
          name={name}
          label={label}
        />
      );
      break;

    case "radio":
      return <RadioFilter options={options} title={label} name={name} />;
    case "date":
      return (
        <DateFilter defaultValue={defaultValue} label={label} name={name} />
      );
    case "text":
    default:
      return <TextFilter name={name} label={label} />;
      break;
  }

};

export default FilterElement;
