import React , { useState, useRef, useEffect, useCallback}from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import Restricted from "../../../Utils/Restricted";


import 'leaflet/dist/leaflet.css' 
import * as L from 'leaflet'

import rewind from "@mapbox/geojson-rewind";
import * as tj from "@mapbox/togeojson";
import Constants from "../../../Utils/Constants";
import { CirclePicker, SketchPicker , TwitterPicker} from 'react-color';
import XMLParser from 'react-xml-parser';
import convert from 'xml-js';



import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'; 

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

var osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains:['mt0','mt1','mt2','mt3']
});

var streets = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains:['mt0','mt1','mt2','mt3']
})


var baseMaps = {
    "Default": osm,
    "Satellite": streets
};

// var overlayMaps = {
//     "Cities": cities
// };

const CoSiteMapGeoMan = ({id,file,color,setData,  ...props}) => {
   
    const parser = new DOMParser();
    const [fileKml , setFileKml ] = useState(null)
    const [kml, setKml] = useState(null)
    const [polColor , setPolColor] = useState( '#0fcc04')
    const [mapContainer, setMapContainer] = useState(null)
    const [featuregrpupe, setFeatureGroup] = useState(null)
    const [editcontrol, setEditControl ] = useState(null)
    const [loading, setLoading] = useState(false)
    const [features, setFeatures] = useState([])
    const [buildL , setBuildL] = useState(null)
   

    
    useEffect(() => {
        if(!file){
            loadKML()
           // setKml(parser.parseFromString(file, "text/xml"))
           
        }
    }, [file]);  

    useEffect(() => {
        if(featuregrpupe && buildL && mapContainer){
            console.log('ben')
            buildL.eachLayer(function (layer) {
                layer.on('pm:edit', e => {
                    editlayer(e)
                });
                layer.on('pm:remove', e => {
                    removelayer(e)
                });
            }); 
        }
    }, [featuregrpupe, buildL , mapContainer])

    useEffect(() => {
        if(color  ){
            setPolColor(color)
           // DrawingContainer(mapContainer)
           // setKml(parser.parseFromString(file, "text/xml"))
        }
    }, [color ]);  
    useEffect(() => {
        if(polColor &&  mapContainer && featuregrpupe){
            DrawingContainer(mapContainer , featuregrpupe)
          
        }
    }, [polColor]);  


    const _onEdited = e  => { 
        console.log('edit')
    } 
    const _onDeleted = e  => { 
        console.log('delete')
    } 


     //if data
     const  loadKML = () => {
        setLoading(true)
        api.get(`/api/cosite/getData/${id}`).then(res => {
          if(res.data){
                console.log('version '+  L.drawVersion)
                let converted = res.data.data
                let zoom = res.data.zoom ? res.data.zoom : 5
                let lat = res.data.lat ? res.data.lat : 36.000
                let lng = res.data.lng ? res.data.lng : 2.09
                setKml(converted)
                const container = document.getElementById('mapId'+id)
                if( container ){
                    var mp = L.DomUtil.get('mapId'+id);
                    if(mp != null){
                        mp._leaflet_id = null;
                    }
                    let  map = L.map('mapId'+id , 
                                        { style: {height: '220px'}}).setView([lat, lng], zoom  )
                    
                    let buildLayer = L.geoJSON(converted , 
                         {
                        style: function(feature) {
                            return {
                                color : feature && feature.properties && feature.properties.fill ? feature.properties.fill : polColor, 
                                weight: feature && feature.properties && feature.properties['stroke-width'] ?  feature.properties['stroke-width'] : 1
                            };
                            // switch (feature.properties.name) {
                            //     case 'Marker 2': return {color: "#F44336"};
                            //     case 'Democrat':   return {color: "#0000ff"};
                            // }
                        }}
                        )
                        setFeatures([converted])

                        L.control.layers(baseMaps).addTo(map);
                       
                       // map.invalidateSize()
                        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                        }).addTo(map);
                        var drawnItems = new L.FeatureGroup();  
                        map.addLayer(drawnItems);
                        setFeatureGroup(drawnItems)
                        buildLayer.eachLayer(function (layer) {
                            drawnItems.addLayer(layer);
                            L.PM.reInitLayer(layer);
                            
                        }); 
                        setBuildL(buildLayer)
                        setMapContainer(map)
                        DrawingContainer(map, drawnItems)
                }
                setLoading(false)
            }
        });
    }

    const DrawingContainer = (map , featureG) =>{ 


        map.pm.setPathOptions({
            color: polColor,
            fillColor: polColor,
            fillOpacity: 0.1,
            weight :1
          });
        map.pm.addControls({  
            position: 'topright',  
            drawCircle: false,  
        });  
    }

    mapContainer  && mapContainer.on('pm:create', (e) => {
        console.log('map zoom '+mapContainer.getZoom() + ' loca '+ mapContainer.getCenter())
        var type = e.layerType,
            layer = e.layer;
        let  feature = layer.feature = layer.feature || {};

        feature.type = feature.type || "Feature"; // Initialize feature.type
        var props = feature.properties = feature.properties || {}; // Initialize feature.properties
        props.fill = polColor;
        props['stroke-width'] = 1

        e.layer.options.pmIgnore = false;
        L.PM.reInitLayer(e.layer);
        featuregrpupe.addLayer(e.layer)
        // setFeatures(featuregrpupe.toGeoJSON())
        // setData(featuregrpupe.toGeoJSON() , mapContainer.getZoom() , mapContainer.getCenter())

        domtoimage.toPng(document.getElementById('mapId'+id))
        .then(function (dataUrl) {
            setFeatures(featuregrpupe.toGeoJSON())
            setData(featuregrpupe.toGeoJSON() , mapContainer.getZoom() , mapContainer.getCenter() , dataUrl)
        })

        layer.on('pm:edit', e => {
            editlayer(e)
        });
        layer.on('pm:remove', e => {
            removelayer(e)
        });
    });

    const editlayer = e =>{ 
            domtoimage.toPng(document.getElementById('mapId'+id))
            .then(function (dataUrl) {
                console.log(dataUrl)
                setFeatures(featuregrpupe.toGeoJSON())
                setData(featuregrpupe.toGeoJSON() , mapContainer.getZoom() , mapContainer.getCenter() , dataUrl)
            // document.body.appendChild(img);
            })
            
    }

    const removelayer = e => {
        featuregrpupe.removeLayer(e.layer);

        domtoimage.toPng(document.getElementById('mapId'+id))
            .then(function (dataUrl) {
                //console.log(dataUrl)
                setFeatures(featuregrpupe.toGeoJSON())
                setData(featuregrpupe.toGeoJSON() , mapContainer.getZoom() , mapContainer.getCenter() , dataUrl)
            // document.body.appendChild(img);
            })
       
    }

    const elementToImage = e => {
        
        // domtoimage.toBlob(document.getElementById('mapId'+id))
        // .then(function (blob) {
        //    // saveAs(blob, 'my-node.png');
            
        // });

        domtoimage.toPng(document.getElementById('mapId'+id))
        .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            console.log(img)
           // document.body.appendChild(img);
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    }
   

    return (
        <React.Fragment>
            {/* <button onClick={e => {elementToImage(e)}}>to image</button> */}
            {loading && <FontAwesomeIcon icon={faSpinner} className={"ml-2"} style={{ fontSize: 16 }} spin />}
            <div  style={{height: '450px', width: '100%' , margin: 'auto'}} className={"col-md-12 map"} id={'mapId'+id}>
            
            </div>
        </React.Fragment>
    )
}
export default CoSiteMapGeoMan;
