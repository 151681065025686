import React from "react";
import { useSelector, useDispatch } from "react-redux";

const Restricted = ({children, permission, role, owner, scope, condition}) => {
const {session} = useSelector(state => ({session: state.session}));
const currentRole = session.user.role;


    if(typeof condition !== "undefined")
        if(!condition)
            return <React.Fragment />


    const roles = {
        agent: 1,
        manager: 2,
        cluster_manager: 3,
        admin: 4
    };
   
    // const {session} = useSelector(state => ({session: state.session}));
    // const currentRole = session.user.role;

    if(!session.user.permissions) {
        return <React.Fragment />;
    }

    if(scope && !session.user.permissions[scope]){
        return <React.Fragment />;}

    if(permission && (!session.user.permissions[scope] || !(permission in session.user.permissions[scope]))) {
        return <React.Fragment />;
    }


    if(role && (roles[currentRole] < roles[role] ))
       { return <React.Fragment />;}

    return children;

}

export default Restricted;
