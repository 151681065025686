import React, { useContext } from "react";
import { FiltersContext } from "../FiltersWrap";
import ReactDatePicker from "react-datepicker";


const moment = require("moment");
const DateFilter = ({ label, name, defaultValue, ...props }) => {
  const context = useContext(FiltersContext);

  const update = (date) => {
      const val = moment(date).format("YYYY-MM-DD");
      context.updateFunc({value: val}, name)
  }

  return (
    <div className={"form-group"}>
      <label>{label}</label>
      

      <ReactDatePicker
        name={name}
        dateFormat={"yyyy-MM-dd"}
        // selected={
        //   this.state.startDate ? new Date(this.state.startDate) : undefined
        // }
        onChange={update}
        className={"form-control"}
        selected={defaultValue ? new Date(defaultValue) : undefined}
      />
    </div>
  );
};

export default DateFilter;
