import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import api from "../Utils/Api";
import Pagination from "../Utils/Pagination";
import Constants from "../Utils/Constants";
import Restricted from "../Utils/Restricted";
import SocketContext from "../Utils/socket-context";
import { buildFilters } from "../Utils/Helpers";
import Select from "react-select";
import TaskGlobal from "../Components/Task/TaskGlobal";


import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";
const mapStateToProps = state => {
    return {
      session: state.session,
      filters: state.filters
    };
  };


  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: () => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  };
class TasklistGlobalContainer extends Component{
  constructor(props){
      super(props);
      this.props.isLoading({
        message: "Loading tasks",
        animate: true
      });
      this.state = {
        taskid: null,
        total: 0,
        managers: [],
        tasks: [],
        agents: [],
        status: [],
        types: [],
        rows: [],
        current: 1,
        pages: 1,
        limit: 15,
        filters: {}

      };
      this.handlers = {
        selectPage: this.selectPage.bind(this),
        loadTasks: this.loadTasks.bind(this),
        updateFilter: this.updateFilter.bind(this)
      };

  }
  changeLimit = e => {

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };
  updateFilter = (event, name) => {

    const value = event.target ? event.target.value : event.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.isLoading();
        this.loadTasks(1);
      }
    );
  };
  componentDidMount() {

    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    }else if (this.props.match.params.type) {

        this.updateFilter({ value: this.props.match.params.task_types }, "task_types");
    } else this.loadTasks();
  }

  loadTasks(p = this.state.current) {

    let nstate = this.state;
    let that = this;
    let crits = buildFilters(this.state.filters);

    api.get(`/api/taskGlobal/fetch?${crits}&page=${p}&limit=${this.state.limit}`).then(function(response) {
      if (response.data.success) {
        response.data.tasks.map(() => {
          nstate.rows.push({ hidden: true });
        });
        that.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          rows: nstate.rows,
          total: response.data.total
        });
        console.log("tasks",response.data.tasks[0].task_type);
      } else {
        if (response.data.errcode === 101) {
          that.props.setAuth(false);
        }
      }
      that.props.doneLoading();
    });
  }
exportPMS = e => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    // let remote = Constants.apiURL;
    // const url = remote + `/api/task/fetch?${crits}&limit=-1&export=true`;
      const url=`http://localhost/netfersmartsolutions-witrack-rollout-backend-984317795bff/public/api/taskGlobal/fetch?${crits}&limit=-1&export=true`
    //window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };
  selectPage = p => {

    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };
render(){
 return(<React.Fragment>
     <div className={"container-header"}>
         Liste des Taches
          {!this.props.session.auth ? <Redirect to="/login" /> : ""}
       </div>
        <div className={"row"}>
          <div className={"col-md-2"}>

            <div className={"side-filters"}>
            <form action="" method="POST" onSubmit={e => this.exportPMS(e)}>
                <input type="hidden" name="token" value={this.props.session.user.token} />
                <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                  Exporter la liste
                </button>
              </form>
              <h4>Recherche</h4>

<Restricted role={"manager"}>
  <div className={"form-group"}>
    <label>Site owner</label>
    <Select
      classNamePrefix={"custom-select"}
      options={[{ label: "Tous", value: "" }, ...this.props.filters.site_owners]}
      placeholder="O&M, Sous-traitant..."
      onChange={e => this.updateFilter(e, "site_owner")}
    />


            </div>
            </Restricted>

            <Restricted role={"cluster_manager"}>
                <div className={"form-group"}>
                  <label>Manager </label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.managers]}
                    placeholder="Manager"
                    onChange={e => this.updateFilter(e, "site_manager")}
                  />
                </div>
              </Restricted>
              <div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  classNamePrefix={"custom-select"}
                options={[{ label: "Tous", value: "" }, ...this.props.filters.wilayas]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "site_wilaya")}
                />
              </div>
              <div className={"form-group"}>
                <label>Type de Taches</label>
                <Select
                  classNamePrefix={"custom-select"}
                //  options={this.state.tasks}
                options={[{ label: "Tous", value: "" } ,...this.props.filters.task_types]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "task_types")}
                />
              </div>
              {this.props.session.user.role !== "agent" && (
              <div className={"form-group"}>
                  <label>Ingenieur</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.agents]}
                    placeholder="Agent"
                    onChange={e => this.updateFilter(e, "agent")}
                  />
                </div>
              )}
              <div className={"form-group"}>
                <label>Date début</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_from")} />
              </div>

              <div className={"form-group"}>
                <label>Date fin</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_to")} />
              </div>

              <div className={"form-group"}>
                <label>Sites</label>
                <input
                  name="site"
                  className={"form-control"}
                  placeholder="Nom ou code site"
                  value={this.state.filters.site}
                  onChange={e => this.updateFilter(e, "site")}
                />
              </div>

              <div className={"form-group"}>
                <label>ID Delivery</label>
                <input
                  type={"text"}
                  name="pm"
                  className={"form-control"}
                  onKeyUp={e => this.updateFilter(e, "pm")}
                  defaultValue={this.state.filters.pm}
                />
              </div>
              </div>
          </div>
             <div className={"col-md-10"}>
               <div className={"row mb-3"}>
                   <div className={"col-md-6 items-found"}>
                     <p>
                       <b>{this.state.total}</b> Taches trouvés
                     </p>
                    </div>
                    <div className={"col-md-6 per-page-wrap"}>
                    <div className={"per-page-content"}>
                      <p>Résultat par page</p>
                      <Select
                        classNamePrefix={"custom-select"}
                        onChange={this.changeLimit}
                        className={"per-page nm"}
                        options={this.props.filters.result_per_page}
                        value={{ label: this.state.limit, value: this.state.limit }}
                      />
                    </div>
                    <Pagination data={this.state} handleClick={this.selectPage} />
                  </div>
                </div>

            <TaskGlobal
              data={this.state}
              rows={this.state.rows}
              handlers={this.handlers}
            />
            </div>
        </div>



 </React.Fragment>)




}


}
const TasklistGlobalConsumer = props => (
    <SocketContext.Consumer>{socket => <TasklistGlobalContainer {...props} socket={socket} />}</SocketContext.Consumer>
  )
export default connect(mapStateToProps, mapDispatchToProps)(TasklistGlobalConsumer);
