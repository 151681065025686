import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HeatMap from "react-heatmap-grid";
import ChartDataLabels from "chartjs-plugin-datalabels";

import Select from "react-select";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { HorizontalBar } from "react-chartjs-2";
import { chartColors, buildFilters } from "../../../../Utils/Helpers";
import api from "../../../../Utils/Api";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

class Agents extends React.Component {
  constructor() {
    super();
    this.state = {
      filters: {
        start_date: moment()
          .startOf("year")
          .format("YYYY-MM-DD"),
        end_date: moment()
          .endOf("year")
          .format("YYYY-MM-DD")
      },
      sitesRatio: 0,
      avgSite: 0,
      taskRatio: 0,
      agentsPerCluster: [],
      agentsPerSitePerCluster: [],
      sitePerAgentAvg: [],
      taskPerAgentPerCluster: []
    };

    this.barOptions = {
      layout: {
        padding: { right: 20 }
      },
      plugins: {
        datalabels: {
          anchor: "end",
          color: "#333",
          align: "end",
          backgroundColor: "rgba(0,0,0,.1)",
          padding: 3,
          font: {
            family: "Open sans",
            weight: "bold"
          }
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#333",
              fontSize: 14,
              fontFamily: "Open sans"
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: "#333",
              fontFamily: "Open sans"
            },
            gridLines: {
              display: true
            }
          }
        ]
      },
      legend: {
        display: false
      }
    };
  }

  loadData = () => {
    const crits = buildFilters(this.state.filters);
    api.get("/api/gpm/dashboard/agents?" + crits).then(res => this.setState({ ...res.data }));
  };

  componentDidMount() {
    this.loadData();
  }

  updateFilter = (name, event) => {
    const value = event.target ? event.target.value : event.value;
    this.setState({ filters: { ...this.state.filters, [name]: value } });
  };

  setStartDate = e => this.setState({ startDate: e });
  setEndDate = e => this.setState({ endDate: e });

  render() {
    let clustersColors = {};

    this.props.filters.clusters.forEach((cluster, idx) => {
      clustersColors[cluster.value] = chartColors[idx];
    });


    const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);

    // Display only even labels
    const xLabelsVisibility = new Array(24).fill(0).map((_, i) => (i % 2 === 0 ? true : false));

    const yLabels = ["Sun", "Mon", "Tue"];
    const data = new Array(yLabels.length)
      .fill(0)
      .map(() => new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 100)));

    const totalSites = this.state.agentsPerCluster.reduce((acc, curr) => acc + parseInt(curr.number), 0) | 1;
    const totalAgents = this.state.agentsPerCluster.reduce((acc, curr) => acc + parseInt(curr.agents), 0) | 1;

    const totalTasks =
      this.state.taskPerAgentPerCluster.reduce(
        (acc, curr) => (isNaN(parseInt(curr.number)) ? acc : acc + parseInt(curr.number)),
        0
      ) | 1;

    console.log("taks : ", this.state.taskPerAgentPerCluster);
    return (
      <div className={"dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>
          <div />
          <div className={"date-filters"}>
            <>
              <b>Owner</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={this.props.filters.site_owners}
                placeholder={"Owner"}
                value={
                  this.props.session.user.owner
                    ? {
                        label: this.props.session.user.owner,
                        value: this.props.session.user.owner === "O&M" ? "inhouse" : this.props.session.user.owner
                      }
                    : undefined
                }
                isDisabled={!!this.props.session.user.owner}
                onChange={e => this.updateFilter("owner", e)}
              />

              <b>Cluster</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[{ label: "Tous", value: "" }, ...this.props.filters.clusters]}
                value={
                  this.props.session.user.cluster
                    ? {
                        label: this.props.session.user.cluster,
                        value: this.props.session.user.cluster
                      }
                    : undefined
                }
                isDisabled={!!this.props.session.user.cluster}
                placeholder={"Cluster"}
                onChange={e => this.updateFilter("cluster", e)}
              />

              <b>Periode </b>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={this.state.filters.start_date ? new Date(this.state.filters.start_date) : undefined}
                onChange={date =>
                  this.updateFilter("start_date", {
                    value: moment(date).format("YYYY-MM-DD")
                  })
                }
                selectsStart
                startDate={this.state.filters.start_date ? new Date(this.state.filters.start_date) : undefined}
                endDate={this.state.filters.end_date ? new Date(this.state.filters.end_date) : undefined}
                className={"dashboard-filter-input"}
              />
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={this.state.filters.end_date ? new Date(this.state.filters.end_date) : undefined}
                onChange={date =>
                  this.updateFilter("end_date", {
                    value: moment(date).format("YYYY-MM-DD")
                  })
                }
                selectsEnd
                startDate={this.state.filters.start_date ? new Date(this.state.filters.start_date) : undefined}
                endDate={this.state.filters.end_date ? new Date(this.state.filters.end_date) : undefined}
                minDate={this.state.filters.start_date ? new Date(this.state.filters.start_date) : undefined}
                className={"dashboard-filter-input ml-3"}
              />
              <button onClick={this.loadData} type={"button"} className={"filter-apply"}>
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>

        <div className={"row dashboard-row"}>
          <div className={"col-md-4 "}>

            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Site/agent</span>
                <span className={"dashboard-box-value"}>{parseFloat(totalSites / totalAgents).toFixed(2)}</span>
              </div>
            </div>

          </div>

          <div className={"col-md-4 "}>

            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>Moyenne site assigné / agent</span>
                <span className={"dashboard-box-value"}>{this.state.sitePerAgentAvg[0] ? parseFloat(this.state.sitePerAgentAvg[0].average).toFixed(2) : 1}</span>
              </div>
            </div>

          </div>

          <div className={"col-md-4 "}>

            <div className={"dashboard-shadow"}>

              <div className={"dashboard-box"}>
                <span className={"dashboard-box-title"}>GPM réalisé / Ingenieur</span>
                <span className={"dashboard-box-value"}>{parseFloat(totalTasks / totalAgents).toFixed(2)}</span>
              </div>
            </div>

          </div>



        </div>

        <div className={"row dashboard-row"}>
          <div className={"col-md-4"}>
            <div className={"single-chart"}>
              <h4>Site par Ingenieur par cluster</h4>
              <HorizontalBar
                plugins={[ChartDataLabels]}
                options={this.barOptions}
                data={{
                  labels: this.state.agentsPerCluster.map(item => item.cluster),
                  datasets: [
                    {
                      data: this.state.agentsPerCluster.map(item => parseFloat(item.ratio).toFixed(2)),
                      backgroundColor: this.state.agentsPerCluster.map(item => clustersColors[item.cluster]),
                      hoverBackgroundColor: this.state.agentsPerCluster.map(item => clustersColors[item.cluster])
                    }
                  ]
                }}
              />
            </div>
          </div>

          <div className={"col-md-4"}>
            <div className={"single-chart"}>
              <h4>Ingenieur par cluster</h4>
              <HorizontalBar
                plugins={[ChartDataLabels]}
                options={this.barOptions}
                data={{
                  labels: this.state.agentsPerCluster.sort((a, b) => b.agents - a.agents).map(item => item.cluster),
                  datasets: [
                    {
                      data: this.state.agentsPerCluster.sort((a, b) => b.agents - a.agents).map(item => item.agents),
                      backgroundColor: this.state.agentsPerCluster.map(item => clustersColors[item.cluster]),
                      hoverBackgroundColor: this.state.agentsPerCluster.map(item => clustersColors[item.cluster])
                    }
                  ]
                }}
              />
            </div>
          </div>

          <div className={"col-md-4"}>
            <div className={"single-chart"}>
              <h4>Task par agent par cluster</h4>
              <HorizontalBar
                plugins={[ChartDataLabels]}
                options={this.barOptions}
                data={{
                  labels: this.state.taskPerAgentPerCluster.map(item => item.cluster),
                  datasets: [
                    {
                      data: this.state.taskPerAgentPerCluster.map(item => item.ratio),
                      backgroundColor: this.state.taskPerAgentPerCluster.map(item => clustersColors[item.cluster]),
                      hoverBackgroundColor: this.state.taskPerAgentPerCluster.map(item => clustersColors[item.cluster])
                    }
                  ]
                }}
              />
            </div>
          </div>
        </div>

        <div className={"row"}>
          {/* <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            xLabelsLocation={"bottom"}
            xLabelsVisibility={xLabelsVisibility}
            xLabelWidth={50}
            data={data}
            squares
            onClick={(x, y) => alert(`Clicked ${x}, ${y}`)}
            cellStyle={(background, value, min, max, data, x, y) => ({
              background: `rgba(66, 86, 244, ${1 -
                (max - value) / (max - min)})`,
              fontSize: "11px",
            })}
            cellRender={(value) => value && `${value}%`}
            title={(value, unit) => `${value}`}
          /> */}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  filters: state.filters,
  session: state.session
});
export default connect(mapState)(Agents);
