import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../Utils/Api";
import UserAuthorizationsOverlay from "./UserAuthorizationsOverlay";
import Restricted from "../../Utils/Restricted";

const UserEditOverlay = ({
  selected_user,
  toggleEditOverlay,
  open,
  role,
  ...props
}) => {
  const [permissionsOverlay, setPermissionsOverlay] = useState(false);

  const submitSiteForm = (e) => {
    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    toggleEditOverlay();

    api.post("/api/users/edit/" + selected_user.userid, data).then((res) => {
      props.reload();
      props.doneLoading();
    });
  };

  const managePermissions = (show) => {
    toggleEditOverlay(show);
    if (typeof show !== "undefined") setPermissionsOverlay(show);
    else {
      setPermissionsOverlay(true);
    }
  };

  return (
    <>
      {permissionsOverlay && (
        <UserAuthorizationsOverlay
          {...props}
          selected_user={selected_user}
          toggleEditOverlay={() => managePermissions(false)}

        />
      )}

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggleEditOverlay}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier le profile</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}

                {role === "manager" && props.user.role === "admin" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster Manager</label>
                    <Select
                      name="cluster_manager"
                      classNamePrefix={"custom-select"}
                      options={props.filters.cluster_managers}
                      defaultValue={{
                        label: selected_user.cluster_manager_username,
                        value: selected_user.cluster_manager_id,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )}

                {role === "agent" &&
                  (props.user.role === "admin" ||
                    props.user.role === "cluster_manager") && (
                    <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                      <label className={"font-exp"}>Manager</label>
                      <Select
                        name="manager"
                        classNamePrefix={"custom-select"}
                        options={props.filters.managers}
                        defaultValue={{
                          label: selected_user.manager_username,
                          value: selected_user.manager_id,
                        }}
                        className={"col-md-8"}
                      />
                    </div>
                  )}

                {role === "agent" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Matricule</label>
                    <input
                      type="text"
                      name="matricule"
                      defaultValue={selected_user.matricule}
                      className={"col-md-8 form-control"}
                    />
                  </div>
                )}

                {role === "cluster_manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster</label>
                    <Select
                      name="cluster"
                      classNamePrefix={"custom-select"}
                      options={props.filters.clusters}
                      defaultValue={{
                        label: selected_user.cluster,
                        value: selected_user.cluster,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )}

                {role === "manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Type</label>
                    <Select
                      name="account_type"
                      classNamePrefix={"custom-select"}
                      options={props.filters.site_owners}
                      defaultValue={{
                        label: selected_user.account_type,
                        value: selected_user.account_type,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )}

                <Restricted role={"admin"}>
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Access authorization</label>
                    <button
                      type="button"
                      onClick={e => managePermissions(e)}
                      className={"cbtn btn btn-sm btn-primary"}
                    >
                      Gérer
                    </button>
                  </div>
                </Restricted>
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom d'utilisateur</label>
                  <input
                    type="text"
                    disabled={true}
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.username}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Prénom</label>
                  <input
                    type="text"
                    name="first_name"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.firstName}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom</label>
                  <input
                    type="text"
                    name="last_name"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.lastName}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Email</label>
                  <input
                    type="email"
                    name="email"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.email}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Téléphone</label>
                  <input
                    type="text"
                    name="phone"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.phone}
                  />
                </div>

                <p>
                  <i>
                    Laissez le champ ci-dessus vide pour ne pas changer le mot
                    de passe
                  </i>
                </p>
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Mot de passe</label>
                  <input
                    type="password"
                    name="password"
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default UserEditOverlay;
