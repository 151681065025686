import React from "react";

const GPMSpareParts = ({ data, ...props }) => {
  return (
    <>
      {data.items &&
        data.items.map(item => (
          <div className={"single-anomalie"}>
            <div className={"row col-md-6"}>
              <label>Item replaced : </label>
              {item.type}
            </div>

            <div className={"row col-md-6"}>
              <label>Quantité : </label>
              {item.quantity} ({item.unit})
            </div>
            
            <div className={"row col-md-6"}>
              <label>Where : </label>
              {item.where}
            </div>

            <div className={"row col-md-6"}>
              <label>Remarks : </label>
              {item.remarks}
            </div>

          </div>
        ))}
    </>
  );
};

export default GPMSpareParts;
