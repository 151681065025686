import React, {Component} from 'react';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import {setFlash, closeFlash} from './../Redux/actions/index';

const mapDispatchToProps = dispatch => {
	return {
		setFlash: e => dispatch(setFlash(e)),
		closeFlash: e => dispatch(closeFlash(e))
	}
}

const mapStateToProps = state => {
	return {
		ui: state.ui
	}
}
class FlashMessage extends Component {

	render() {
		
		const {flashMessage} = this.props.ui;
		return (
			<div className={"flash-alert"+(flashMessage.active ? ' active' : "")}>
				<div className={"alert alert-"+flashMessage.classes} style={flashMessage.style}> 
					<span>{flashMessage.message}</span>

					<span onClick={this.props.closeFlash} className={"flash-close-btn"}> 
						<FontAwesomeIcon icon={faTimes} />
					</span>

				</div>
			</div>)
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);