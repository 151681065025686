import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import api from '../../Utils/Api';
import Constants from '../../Utils/Constants';


const moment = require("moment");

class Manuals extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showNew: false,
            files: [],
            loading: true,
            selectedFile: {}
        }
    }


    componentDidMount() {
        this.loadFiles();
    }

    loadFiles = () => {
        this.setState({loading: true})
        api.get('/api/settings/files')
        .then(res => {
            if(res.data.success)
                this.setState({files: res.data.files})

            this.setState({loading: false})
        })
    }

  render() {



    return (<>
        <h3>
            Fichiers & guides
            {this.state.loading && <FontAwesomeIcon className={"ml-4"} icon={faSpinner} spin />}
        </h3>

        <div className={"table-resposive mt-4"}>

            <table className={"table"}>
                <thead>
                    <tr>
                        <th>Fichier</th>
                        <th>Description</th>
                        <th>Date d'ajout</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.files.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.url}</td>
                                <td>{item.description}</td>
                                <td>{moment(item).format("YYYY-MM-DD")}</td>
                                <td className={"site-plan-col-action"}>
                                    <a target={"_blank"} href={Constants.apiURL+"/upload/"+item.url} className={"task-action-btn"}>
                                        <FontAwesomeIcon icon={faEye} />
                                        Voir
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </>);
  }


}

export default Manuals;
