import React, { useContext } from "react";
import { FiltersContext } from "../FiltersWrap";

const RadioFilter = ({ options, title, name, ...props }) => {
  const context = useContext(FiltersContext);

  const update = (e, el) => {
    context.updateFunc({ value: e.target.checked ? el.value : "" }, name);
  };

  return (
    <div className={"form-group fposb"}>
      {options.map((el, key) => (
        <div className="custom-radio ml-4">
          <input
            onChange={(e) => update(e, el)}
            name={name}
            type="radio"
            className="custom-control-input"
            id={`custom-radio-filter-${name}-${key}`}
            defaultChecked={el.default}
          />
          <label
            className="custom-control-label"
            htmlFor={`custom-radio-filter-${name}-${key}`}
          >
            {el.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioFilter;
